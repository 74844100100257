import { UserController } from "./user_controller"
// import Rails from "@rails/ujs";

export default class extends UserController {
  static targets = [
    'mobileNumber', 'mobileNumberErrorMessage', 'otp1',
    'otp2', 'otp3', 'otp4', 'otpVerifyDispay', 'triggerOTP',
    'retriggerTimer', 'time'
  ]

  counter = 60;

  verifyMobileProfileAndTriggerOtp(e){
    let mobileNumber = this.mobileNumberTarget.value
    if (mobileNumber.length != 10) {
      this.mobileNumberErrorMessageTarget.textContent = 'Mobile Number is not Valid'
      return;
    }
    // clearInterval(this.interval)
    $(e.target).prop('disabled', true)
    $(e.target).html("<i class='fa fa-spinner fa-spin'></i> Triggering OTP...")

    $.ajax({
      url: '/trigger_otp_verify_mobile_number',
      method: 'GET',
      data: {
        mobile_number: mobileNumber
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          toastr.success('Successfully triggered OTP')
          $(this.otpVerifyDispayTarget).show();
          $(this.triggerOTPTarget).hide();
          $(this.retriggerTimerTarget).show();
          this.counter = 60
          this.timer()
        } else {
          this.mobileNumberErrorMessageTarget.textContent = response.message
        }
      }
    }).done(function() {
      $(e.target).prop('disabled', false)
      $(e.target).html("SEND OTP")
    });
  }

  verifyMobileAndTriggerOtp(e) {
    console.log(this.mobileNumberTarget.value);
    let mobileNumber = this.mobileNumberTarget.value
    if (mobileNumber.length != 10) {
      this.mobileNumberErrorMessageTarget.textContent = 'Mobile Number is not Valid'
      return;
    }
    // clearInterval(this.interval)

    $.ajax({
      url: '/trigger_otp_forgot_password',
      method: 'GET',
      data: {
        mobile_number: mobileNumber
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          toastr.success('Successfully triggered OTP')
          $(this.otpVerifyDispayTarget).show();
          $(this.triggerOTPTarget).hide();
          $(this.retriggerTimerTarget).show();
          this.counter = 60
          this.timer()
        } else {
          this.mobileNumberErrorMessageTarget.textContent = response.message
        }
      }
    })
  }

  timer(){
    let interval = setInterval(() => {
      this.counter--;
      // Display 'counter' wherever you want to display it.
      if (this.counter <= 0) {
        clearInterval(interval);
        // $('#timer').html("<h3>Count down complete</h3>");
        $(this.retriggerTimerTarget).hide();
        this.triggerOTPTarget.textContent = 'RESEND OTP'
        $(this.triggerOTPTarget).show()
        return;
      } else {
        $('#time').text(this.counter);
        console.log("Timer --> " + this.counter);
      }
    }, 1000);
  }


  verifyOtpProfile(e) {
    e.preventDefault()
    if (this.otp1Target.value == '' || this.otp2Target.value == '' || this.otp3Target.value == '' || this.otp4Target.value == '') {
      this.otpErrorMessageTarget.textContent = 'Enter Valid 4 digit OTP'
      return
    }

    $(e.target).prop('disabled', true)
    $(e.target).html("<i class='fa fa-spinner fa-spin'></i> Verifying...")

    $.ajax({
      url: '/verify_otp_profile',
      method: 'GET',
      data: {
        mobile_number: this.mobileNumberTarget.value,
        otp: `${this.otp1Target.value}${this.otp2Target.value}${this.otp3Target.value}${this.otp4Target.value}`
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          window.location.href = '/'
        } else {
          this.otpErrorMessageTarget.textContent = response.message
        }
      }
    }).done(function() {
      $(e.target).prop('disabled', false)
      $(e.target).html("SEND OTP")
    });
  }

  verifyOtp(e) {
    e.preventDefault()
    if (this.otp1Target.value == '' || this.otp2Target.value == '' || this.otp3Target.value == '' || this.otp4Target.value == '') {
      this.otpErrorMessageTarget.textContent = 'Enter Valid 4 digit OTP'
      return
    }
    $.ajax({
      url: '/verify_otp_forgot_password',
      method: 'GET',
      data: {
        mobile_number: this.mobileNumberTarget.value,
        otp: `${this.otp1Target.value}${this.otp2Target.value}${this.otp3Target.value}${this.otp4Target.value}`
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          window.location.href = response.password_reset_url
        } else {
          this.otpErrorMessageTarget.textContent = response.message
        }
      }
    })
  }

}