import { ErrorController } from "./error_controller.js"

export default class extends ErrorController {

  static targets = [
    'cartIds', 'subTotal', 'totalAmount', 'choosenDeliveryAddressId', 'choosenPickUpAddressId', 'choosenPickUpFullAddress', 'choosenDeliveryFullAddress',
    'deliveryAddresses', 'deliveryAddressDisplay', 'pickUpAddressDisplay', 'deliveryChargesDisplay', 'pickUpAddresses', 'delivery', 'customerPickUp', 'deliveryCharge',
    'discountCouponInput', 'discountCouponApplyButton', 'discountCouponRemoveButton', 'discountCouponErrorMessage',
    'discountCodeDisplay', 'discountCodeCouponDisplayValue', 'discountAmount', 'taxValueDisplay', 'addressTypeHome', 'addressTypeOffice', 'addressTypeOther','addressLine1', 'addressLine2', 'savePinCode','createdAddress','orderCheckOut'
  ]

  connect() {
    console.log('checkout page connected');
  }

  increaseItemQuantity(e) {
    // e.target.dataset.id
    let quantity = parseInt(this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent)
    quantity++;
    this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent = quantity;
    this.updateTotalAmount()
  }

  decreaseItemQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent)
    quantity--;
    if (quantity < 1) {
      console.log('Quantity should not br lesser than the 1');
      return;
    }
    this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent = quantity;
    this.updateTotalAmount();
  }

  customerPickUpChoose(e) {
    $(this.deliveryAddressDisplayTarget).hide(200, () => {
      $(this.pickUpAddressDisplayTarget).show(400);
      $(this.deliveryChargesDisplayTarget).hide(200);
    });
    this.updateTotalAmount();
  }

  deliveryAddressChoose(e) {
    $(this.pickUpAddressDisplayTarget).hide(200, () => {
      $(this.deliveryAddressDisplayTarget).show(400);
      $(this.deliveryChargesDisplayTarget).show(200);
    });
    this.updateTotalAmount();
  }

  discountCouponValidateApply(e) {
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.discountCouponInputTarget.value, this.discountCouponErrorMessageTarget, 'Enter Discount coupon code to apply', is_valid);
    if (!is_valid) {
      return;
    }
    $.ajax(
      {
        url: "/validate_discount_code",
        method: "POST",
        async: false,
        data: { discount_code: this.discountCouponInputTarget.value },
        success: response => {
          if (response.response_code == 200) {
            $(this.discountCouponInputTarget).prop('disabled', true);
            $(this.discountCouponApplyButtonTarget).hide(200, () => $(this.discountCouponRemoveButtonTarget).show(400))
            this.discountCouponErrorMessageTarget.textContent = ''
            this.discountCodeCouponDisplayValueTarget.innerHTML = this.discountCouponInputTarget.value;
            $(this.discountCodeDisplayTarget).show(400);
          } else {
            $(this.discountCouponInputTarget).prop('disabled', false);
            $(this.discountCouponRemoveButtonTarget).hide(200, () => $(this.discountCouponApplyButtonTarget).show(400))
            this.discountCouponErrorMessageTarget.textContent = response.message;
            $(this.discountCodeDisplayTarget).hide(200);
          }
        }
      }
    );
    this.updateTotalAmount();
  }

  discountCouponRemove(e) {
    $(this.discountCouponInputTarget).prop('disabled', false);
    this.discountCouponInputTarget.value = ''
    $(this.discountCouponRemoveButtonTarget).hide(200, () => $(this.discountCouponApplyButtonTarget).show(400))
    $(this.discountCodeDisplayTarget).hide(200);
    this.updateTotalAmount();
  }

  updateTotalAmount() {
    let cartIDWithQuantity = this.cartIdsTargets
      .map(element => {
        return {
          cart_id: element.textContent,
          quantity: parseInt(this.targets.find(`quantityCartPage${element.textContent}`).textContent)
        }
      })
    let delivery_address_id;
    if (this.deliveryTarget.checked) {
      delivery_address_id = this.choosenDeliveryAddressIdTarget.textContent
    }
    let discount_coupon;
    if (this.discountCouponInputTarget.disabled) {
      discount_coupon = this.discountCouponInputTarget.value
    }
    $.ajax({
      url: 'calculate_total_amount',
      method: 'GET',
      data: {
        cart_items: JSON.stringify(cartIDWithQuantity),
        delivery_address_id: delivery_address_id,
        discount_coupon: discount_coupon
      },
      success: (response) => {
        this.subTotalTarget.textContent = response.sub_total
        this.totalAmountTargets.forEach(element => element.textContent = response.total_amount)
        if (response.delivery_charges) {
          this.deliveryChargeTarget.textContent = response.delivery_charges
        }
        if (response.discount_amount) {
          this.discountAmountTarget.innerHTML = response.discount_amount
        }
        if (response.tax_amount) {
          this.taxValueDisplayTarget.innerHTML = response.tax_amount
        }
      }
    })
  }

  changeDeliveryAddress(e) {
    let selectedAddressElement = this.deliveryAddressesTargets.find(element => element.checked);
    this.choosenDeliveryAddressIdTarget.textContent = selectedAddressElement.dataset.id;
    this.choosenDeliveryFullAddressTarget.textContent = selectedAddressElement.dataset.address;
    $('#change_address_modal').modal('hide');
    this.updateTotalAmount();
  }

  changePickUpAddress(e) {
    let selectedAddressElement = this.pickUpAddressesTargets.find(element => element.checked);
    this.choosenPickUpAddressIdTarget.textContent = selectedAddressElement.dataset.id;
    this.choosenPickUpFullAddressTarget.textContent = selectedAddressElement.dataset.address;
    $('#select_pickup_modal').modal('hide');
    this.updateTotalAmount();
  }

  delete_cart_items(e){
    let cart_id = e.target.dataset.id
    console.log(cart_id);
    $.ajax({
      url: `/checkout/${cart_id}`,
      method: 'DELETE',
      data: {
        cart_id: e.target.dataset.id,
      },
      success: (response) => {
        if (response.response_code == 200) {
          alert('Successfully deleted the cart item');
        }
      }
    })
  }

  saveAddress(e){
    e.preventDefault();
    let defaultAddressType = '';
    if (this.addressTypeHomeTarget.checked) {
      defaultAddressType = 'Home'
    }else if (this.addressTypeOfficeTarget.checked) {
      defaultAddressType = 'Office'
    }else if (this.addressTypeOtherTarget.checked) {
      defaultAddressType = 'Other'
    }

    $.ajax({
      url: '/add_address_checkout',
      method: 'POST',
      data: {
        address_type: defaultAddressType,
        address_line1: this.addressLine1Target.value,
        address_line2: this.addressLine2Target.value,
        pincode: this.savePinCodeTarget.value
      },
      success: (response) => {
        if (response.response_code == 200) {  
          this.choosenDeliveryAddressIdTarget.innerHTML = response.address_id
          this.choosenDeliveryFullAddressTarget.innerHTML = response.full_address
          this.updateTotalAmount();
          alert('Address created Successfully!');          
          $('#change_address_modal').modal('hide');
         let data = '<div class="col-md-6"><label class="am-form__checkbox-label d-flex flex-column align-items-start flex-wrap"><div class="am-form__checkbox-custom am-form__checkbox-custom--radio d-flex align-items-start"><input class="am-form__checkbox-input am-form__checkbox-input--radio" type="radio" name="delivery_type" id="address_delivery_home" data-check-out-target="deliveryAddresses" data-id='+ response.address_id +' data-address='+ response.full_address +' ><span class="am-form__checkbox-mark am-form__checkbox-mark--radio"></span><span class="am-form__checkbox-text am-form__checkbox-text--radio ps-6">'+ response.address_type+'</span></div><p class="am-form__checkbox-desc text-xs text-start ms-6 mb-1 leading-tight">'+response.full_address +'</p></label></div>'
         $(this.createdAddressTarget).append(data)
         this.clearCartAddressForm();
         $('#change_address_modal').modal('hide');
        }  
        else if (response.response_code == 400) {
          toastr.error('Zip code does not Exist!')
        }
      }
    }).fail(() => {
      toastr.error('Something went wrong, try again later')
    })

  }
  
  clearCartAddressForm(){
    this.addressTypeHomeTarget.checked = true
    this.addressLine1Target.value = ''
    this.addressLine2Target.value = ''
    this.savePinCodeTarget.value = ''
  }   

  orderCheckOut() {
    let cart_ids = this.cartIdsTargets
    console.log(cart_ids)
    let cart_items_quantity = cart_ids.map(element => {
      return { 
        cart_id: element.textContent,
        quantity: this.targets.find('quantityCartPage'+element.textContent)
      }
    })
    console.log(cart_items_quantity)

    // alert("Yes clicked orderCheckOut")
    // $.ajax({
    //   url: '/checkout_orders',
    //   method: 'POST',
    //   data: {
        
    //   },
    //   success: (response) => {
    //     if (response.response_code == 200) { 

    //     }
    //   }  
    // })
  }    
}