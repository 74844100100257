$(document).ready(function() {
  $('#customer_pickup_redeem_meals_checkout').on('click', (e) => {
    $('#delivery_address_display_redeem_meals').slideUp(()=> {
      $('#pick_up_display_redeem_meals').slideDown()
    })
    $('#delivery_pick_up_date_redeem').html('Pickup Date')
    // update value
    updatePrice();
  })

  $('#address_delivery_redeem_meals_checkout').on('click', (e) => {
    $('#pick_up_display_redeem_meals').slideUp(()=> {
      $('#delivery_address_display_redeem_meals').slideDown()
    })
    $('#delivery_pick_up_date_redeem').html('Delivery Date')
    // update value
    updatePrice();
  })

  hideOtherAddressType = () => {
    $('#otherAddressTypeDiv').hide(400);
  }

  showOtherAddressType = () => {
    $('#otherAddressTypeDiv').show(400);
  }

  $('#add_new_address').on('click', e => {

    let defaultAddressType = $('input[name=address_type]:checked').val()
  
    let make_default = $('input[name=default]').prop('checked')
    let address1 = $('#addressLine1').val()
    let address2 = $('#addressLine2').val()
    let other_address_type = $('#otherAddressTypeInput').val()
    
    let not_valid = false
    if (address1 == '') {
      $('#checkoutLine1InputErrorMessage').html('Address Line1 is required Required');
      not_valid = true  
    }
  
    if (address2 == '') {
      $('#checkoutLine2InputErrorMessage').html('Address Line2 is required Required');
      not_valid = true
    }
  
    if (defaultAddressType == 'Other' && other_address_type == '') {
      $('#otherAddressTypeInputErrorMessage').html('Address Other Type is requierd')
      not_valid = true
    }
  
    if (not_valid) {
        return false;
    }

    $('#add_new_address').html("<i class='fa fa-spinner fa-spin'></i> Adding address...");
    $('#add_new_address').prop('disabled', true);
  
    if (defaultAddressType == "Home") {
      defaultAddressType = 'Home'
    } else if (defaultAddressType == "Office") {
      defaultAddressType = 'Office'
    } else if (defaultAddressType == "Other" ) {
      defaultAddressType = 'Other'
      defaultAddressType =  $('#otherAddressTypeInput').val()
    }
  
      $.ajax({
        url: '/add_address_checkout',
        method: 'POST',
        data: {
          address_type: defaultAddressType,
          address_line1: $('#addressLine1').val(),
          address_line2: $('#addressLine2').val(),
          pincode: $('#savePinCode').val(),
          default: make_default,
          other_address_type: $('#otherAddressTypeInput').val()
        },
        success: (response) => {
          if (response.response_code == 200) {
            $('#add_new_address').html("Save & Select");
            $('#add_new_address').prop('disabled', false);
            $('#proceed_to_pay').prop('disabled', false);
            $('#delivery_location_id').val(response.address_id)
            $('#delivery_display_full_address').html(response.full_address)
            $('#delivery_display_full_address').removeClass('text-danger')
            // $('#default').prop('disabled', false)
            // $('#redeemDefault').removeAttr('disabled');
            $("#redeemDefault").prop('onclick', null);
            $('#redeemDefault').prop('checked', false)
            $('#cart_add_address_show').hide()
  
            let data = `
                        <div class="col-md-6 address-count-redeem">
                        <div class="am-global__address">
                          <label class="am-form__checkbox-label d-flex flex-column align-items-start flex-wrap">
                            <div class="am-form__checkbox-custom am-form__checkbox-custom--radio mb-1 d-flex align-items-start">
                              <div id='${response.address_id}_deliveryAddresses' style="display:none">${response.full_address}</div>
                              <input class="am-form__checkbox-input am-form__checkbox-input--radio" type="radio" name="delivery_address" value="${response.address_id}"  data-check-out-target="deliveryAddresses" data-id='${response.address_id}' data-full-address='${response.full_address}' checked >
                              <span class="am-form__checkbox-mark am-form__checkbox-mark--radio"></span>
                              <span class="am-form__checkbox-text am-form__checkbox-text--radio ps-6">${response.address_type}</span>
                            </div>
                            <p class="am-form__checkbox-desc text-sm text-start ms-6 mb-1 leading-tight">${response.full_address}</p>
                          </label>
                        </div>
                      </div>
                      `

            $('#createdAddress').append(data)
            if ($('.address-count-redeem').length > 1) {
              $('#delivery_select_redeem').prop('disabled', false)
            }
            clearCartAddressForm();
            $('#change_address_modal').modal('hide');
            toastr.success(response.message)
            updatePrice();
          }
          else if (response.response_code == 400) {
            toastr.warning(response.message)
          }
          $('#add_new_address').html("Save & Select");
          $('#add_new_address').prop('disabled', false);
        }
      })
      return false
  })

  clearCartAddressForm = () => {
    $('input[name=address_type]:checked').val('')
    $('#addressLine1').val('') 
    $('#addressLine2').val('') 
    $('#savePinCode').val('')    
    $('#otherAddressTypeInput').val('')
    $('#otherAddressTypeDiv').hide();
  }

  $('#submit_button').on('click', (e) => {
    let total_meal_available_to_redeem = parseInt($('#total_meal_available_to_redeem').html())
    let redeemed_meals_count_up = parseInt($('#redeemed_meals_count_up').html())
    let address_type = $('input[name=delivery_type]:checked').val()
    if (address_type == 'delivery' && $('#delivery_location_id').val() == '') {
      toastr.error('Add address inorder to complete the order for delivery');
      e.preventDefault();
    }
    if (redeemed_meals_count_up > total_meal_available_to_redeem) {
      toastr.error('Availabel meals is lesser than redeem meals')
      e.preventDefault();
      return;
    }

    let delivery_type = $('input[name=delivery_type]:checked').val()

    if (delivery_type == 'delivery') {
      $.ajax({
        url: '/validate_address',
        async: false,
        data: {
          address_id: $('#delivery_location_id').val()
        },
        success: response => {
          if (response.response_code != 200) {
            e.preventDefault();
            toastr.warning(response.message)
          }
        }
      })
    }

    if ($('#total_charges').html() != Number("")) {
      e.preventDefault();
      $('#select_saved_card_redeem').modal('show')
    }
    
    if ($('input[name=delivery_type]:checked').val() == "pick_up") {
      if ($('#pick_up_location_id').val() == '') {
        toastr.error('Please choose a pickup or delivery location for your meals to be delivered')
        e.preventDefault();
        return;
      }}
  })

  window.specialInstructionsRedeem = (modal, pop_up_instruction, instruction) => {
    $(`#${instruction}`).val($(`#${pop_up_instruction}`).val())
    $(`#${modal}`).modal('hide')
  }

  $('#saved_card_pop_up_submit').on('click', (e) => {
    $('#saved_card_id').val($('input[name=saved_card]:checked').val())
    let total_meal_available_to_redeem = parseInt($('#total_meal_available_to_redeem').html())
    let redeemed_meals_count_up = parseInt($('#redeemed_meals_count_up').html())
    let address_type = $('input[name=delivery_type]:checked').val()
    let is_error = false;
    if (address_type == 'delivery' && $('#delivery_location_id').val() == '') {
      toastr.error('Add address inorder to complete the order for delivery');
      // e.preventDefault();
      is_error = true;
    }
    if (redeemed_meals_count_up > total_meal_available_to_redeem) {
      toastr.error('Availabel meals is lesser than redeem meals')
      is_error = true;
    }

    let delivery_type = $('input[name=delivery_type]:checked').val()

    if (delivery_type == 'delivery') {
      $.ajax({
        url: '/validate_address',
        async: false,
        data: {
          address_id: $('#delivery_location_id').val()
        },
        success: response => {
          if (response.response_code != 200) {
            is_error = true;
            toastr.warning(response.message)
          }
        }
      })
    }

    if (!is_error) {
      $('#redeem_checkout_form').submit()
    }
  })

  $('#pick_up_select_redeem').on('click', (e) => {
    let pick_up_element = $('input[name=pickup_address]:checked');
    $('#pick_up_display_full_address').css({"color": ""})
    $('#pick_up_location_id').val(pick_up_element.val());
    $('#pick_up_display_full_address').html(pick_up_element.data('full-address'));
    $('#select_pickup_modal').modal('hide');
    updatePrice();
    console.log('Successfully changed the pick up location');
  })

  $('#delivery_select_redeem').on('click', (e) => {
    console.log('clicked');
    let delivery_element = $('input[name=delivery_address]:checked');
    $('#delivery_location_id').val(delivery_element.val());
    $('#delivery_display_full_address').html(delivery_element.data('full-address'));
    $('#change_address_modal').modal('hide');
    updatePrice();
    console.log('Successfully changed the delivery location');
  })

  increaseQuantity = (grab_and_go_meal_id) => {
    let quantity = parseInt($(`#quantity_${grab_and_go_meal_id}`).html())
    let total_meal_available_to_redeem = parseInt($('#total_meal_available_to_redeem').html())
    let redeemed_meals_count_up = parseInt($('#redeemed_meals_count_up').html())
    redeemed_meals_count_up++;
    if (redeemed_meals_count_up > total_meal_available_to_redeem) {
      toastr.warning('Cannot increase quantity, available meals exceeded, Change Card or Recharge')
      return;
    }
    $('#redeemed_meals_count_up').html(redeemed_meals_count_up);
    $(`#quantity_${grab_and_go_meal_id}`).html(quantity+1)
    $(`#grab_and_go_meal_quantity_${grab_and_go_meal_id}`).val(quantity+1);
    updatePrice();
  }

  decreaseQuantity = (grab_and_go_meal_id) => {
    let quantity = parseInt($(`#quantity_${grab_and_go_meal_id}`).html())
    let redeemed_meals_count_up = parseInt($('#redeemed_meals_count_up').html())
    quantity--;
    if (quantity < 1) {
      toastr.warning('Quantity cannot be lesser than 1')
      return;
    }
    $(`#quantity_${grab_and_go_meal_id}`).html(quantity);
    redeemed_meals_count_up--;
    $('#redeemed_meals_count_up').html(redeemed_meals_count_up);
    $(`#grab_and_go_meal_quantity_${grab_and_go_meal_id}`).val(quantity);
    updatePrice();
  }

  updatePrice = () => {
    let delivery_type = $('input[name=delivery_type]:checked').val()
    if (delivery_type == 'delivery' && (parseInt($('#total_meal_available_to_redeem').html()) <= parseInt($('#redeemed_meals_count_up').html()) )) {
      if ($('#delivery_location_id').val() == '') {
        toastr.error('Add address inorder to complete the order for delivery');
        return;
      }
      $.ajax({
        url: '/get_delivery_charges',
        method: 'GET',
        data: {
          address_id: $('#delivery_location_id').val()
        },
        success: (response) => {
          if (response.response_code == 200) {
            $('#delivery_charges').html(response.delivery_charges.toFixed(2))
            $('#tax_charges').html(response .tax.toFixed(2))
            $('#total_charges').html(response.total_amount.toFixed(2))
          }
        }
      })
    }else{
      $('#delivery_charges').html('0.00')
      $('#tax_charges').html('0.00')
      $('#total_charges').html('0.00')
    }
  }

  if($('#address_delivery_redeem_meals_checkout').is(":checked")){
    updatePrice();
  }

  changeCardRedeemCheckout = (redeem_card_id, card_complete_code, element) => {
    $('#total_meal_available_to_redeem').html($(`#redeem_meals_checkout_pop_up_available_meals_${redeem_card_id}`).html());
    $('#redeem_card_id').val(redeem_card_id);
    $('#card_code').html(card_complete_code);
    $('.am-meal__card-choose--selected')[0].classList.remove('am-meal__card-choose--selected')
    element.classList.add('am-meal__card-choose--selected')
    updatePrice()
  }

  $('#rechargeCardCheckout').on('click', (e) => {
    $('#rechargeCardCheckout').prop('disabled', true)
    $('#rechargeCardCheckout').html("<i class='fa fa-spinner fa-spin'></i> Recharging...")
    console.log('clicked');
    $.ajax({
      url: '/charge-customer-for-redeeming-cards',
      method: 'GET',
      data: {
        redeem_card_id: $('#redeem_card_id').val(),
        user_card_id: $('input[name=checkout_redeem_meals_payment_cards]:checked').val()
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#total_meal_available_to_redeem').html(response.available_meals)
          $(`#redeem_meals_checkout_pop_up_available_meals_${$('#redeem_card_id').val()}`).html(response.available_meals)
          $('#recharge_lets_eat_card_checkout').modal('hide')
          toastr.success('Recharge of Card is Successful')
          updatePrice()
        }else{
          console.log('Failure');
        }
        $('#rechargeCardCheckout').prop('disabled', false)
        $('#rechargeCardCheckout').html("Recharge Card")
      }
    })
  })

});