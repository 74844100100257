import { ErrorController } from "./error_controller.js"

export default class extends ErrorController {

  static targets = [
    'homeAddressType', 'officeAddressType', 'otherAddressType', 'otherAddressTypeDiv',
    'line1', 'line2', 'city', 'state', 'country',
    'pincode', 'line1ErrorMessage', 'line2ErrorMessage', 'cityErrorMessage',
    'pincodeErrorMessage', 'otherAddressTypeInput', 'otherAddressTypeInputErrorMessage',
    'addressForm', 'addressId', 'default', 'addressRadio','deleteAddress'
  ]

  connect(){
    console.log('address connected');
  }

  hideOtherAddressInput = () => $(this.otherAddressTypeDivTarget).hide('1000');

  showOtherAddressInput = () => $(this.otherAddressTypeDivTarget).show('1000');


  addAddress() {
    // $(this.newAddressTarget).hide('1000');
    this.clearAddressFormErrorMessages();
    this.showAddressForm();
    // this.saveButtonTarget.textContent = 'Save Address';
  }

  clearAddressFormErrorMessages(){
    this.line1Target.value = ''
    this.line1ErrorMessageTarget.textContent = ''
    this.line2Target.value = ''
    this.line2ErrorMessageTarget.textContent = ''
    this.cityTarget.value = ''
    this.cityErrorMessageTarget.textContent = ''
    this.pincodeTarget.value = ''
    this.pincodeErrorMessageTarget.textContent = ''
    this.otherAddressTypeInputTarget.value = ''
    this.pincodeErrorMessageTarget.textContent = ''
  }

  showAddressForm = () => $(this.addressFormTarget).show('1000');
  hideAddressForm = () => $(this.addressFormTarget).hide('1000');

  editAddress(e){
    this.fetchAddressAndDisplayInForm(e.target.dataset.id);
  }

  editAddressButton(e){
    let checkedAddressElement = this.addressRadioTargets.find( radioElement => radioElement.checked)
    this.fetchAddressAndDisplayInForm(checkedAddressElement.dataset.id);
  }

  deleteAddress(e){
    let address_id = e.target.dataset.id
    console.log(address_id);
    $.ajax({
      url: `/address_delete/${address_id}`,
      method: 'GET',
      async: false,
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          location.reload();
          return;
        }
      }
    });
  }

  fetchAddressAndDisplayInForm(id){
    this.clearAddressFormErrorMessages();

    this.showAddressForm();
    $.ajax({
      url: `/address/${id}`,
      method: 'GET',
      async: false,
      success: response => {
        console.log(response);
        if (response.response_code != 200) {
          toastr.warning('Something went wrong, Try again later')
          return;
        }
        this.addressIdTarget.value = response.address.id
        if (response.address.address_type == 'Home') {
          this.homeAddressTypeTarget.checked = true;
          this.hideOtherAddressInput();
        }else if (response.address.address_type == 'Office') {
          this.officeAddressTypeTarget.checked = true;
          this.hideOtherAddressInput();
        }else{
          this.otherAddressTypeTarget.checked = true;
          this.otherAddressTypeInputTarget.value = response.address.address_type;
          this.showOtherAddressInput();
        }
        this.address_type = response.address.address_type
        this.line1Target.value = response.address.line1
        this.line1 = response.address.line1
        this.line2Target.value = response.address.line2
        this.line2 = response.address.line2
        this.cityTarget.value = response.address.city
        this.city = response.address.city
        this.pincodeTarget.value = response.address.pincode
        this.pincode = response.address.pincode
        this.default = response.address.default
        if (response.address.default) {
          this.defaultTarget.checked = true;
          this.defaultTarget.onclick = () => { return false }
        }else{
          this.defaultTarget.checked = false;
          this.defaultTarget.onclick = ''
        }
      }
    })
  }

  saveAddress(e) {
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.line1Target.value, this.line1ErrorMessageTarget, 'Address Line1 is required', is_valid);
    // is_valid = this.validationIsFieldEmpty(this.line2Target.value, this.line2ErrorMessageTarget, 'Address Line1 is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.cityTarget.value, this.cityErrorMessageTarget, 'City is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.stateTarget.value, this.stateErrorMessageTarget, 'State is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.countryTarget.value, this.countryErrorMessageTarget, 'Country is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.pincodeTarget.value, this.pincodeErrorMessageTarget, 'Pincode is required', is_valid);

    if (this.otherAddressTypeTarget.checked) {
      is_valid = this.validationIsFieldEmpty(this.otherAddressTypeInputTarget.value, this.otherAddressTypeInputErrorMessageTarget, 'Enter Address type', is_valid);
    }

    if (!is_valid) {
      e.preventDefault();
      return;
    }

    let defaultAddressType = '';
    if (this.homeAddressTypeTarget.checked) {
      // this.homeAddressTypeTarget.checked = true;
      defaultAddressType = 'Home'
    }else if (this.officeAddressTypeTarget.checked) {
      defaultAddressType = 'Office'
    }else{
      defaultAddressType = this.otherAddressTypeInputTarget.value
    }
    if (this.line1 == this.line1Target.value && this.line2 == this.line2Target.value && this.city == this.cityTarget.value && this.pincode == this.pincodeTarget.value && defaultAddressType == this.address_type && this.defaultTarget.checked == this.default) {
      console.log('no changes were made');
      e.preventDefault();
      return;
    }

    $.ajax({
      url: '/validate_pincode',
      method: 'GET',
      async: false,
      data: {
        pincode: this.pincodeTarget.value
      },
      success: (response) => {
        if (!response.valid) {
          this.pincodeErrorMessageTarget.textContent = 'This zip code is not serviceable for delivery'
          e.preventDefault();
        }
      }
    })
  }
}