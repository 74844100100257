import { UserController } from "./user_controller"
import { EMAIL_REGEX, UPPERCASE_REGEX, LOWERCASE_REGEX, DIGIT_REGEX } from '../custom_constant';
// import Rails from "@rails/ujs";

export default class extends UserController {

  static targets = [ 'firstName', 'firstNameErrorMessage',
    'lastName', 'lastNameErrorMessage', 'mobileNumber', 'mobileNumberErrorMessage',
    'confirmPassword', 'confirmPasswordErrorMessage',
    'loginEmail', 'loginPassword', 'loginEmailErrorMessage',
    'loginPasswordErrorMessage']

  userRegistrationValidation(e) {
    let firstName = this.firstNameTarget.value;
    let firstNameErrorMessage = this.firstNameErrorMessageTarget;
    let lastName = this.firstNameTarget.value;
    let lastNameErrorMessage = this.lastNameErrorMessageTarget;
    let email = this.emailTarget.value;
    let emailErrorMessage = this.emailErrorMessageTarget;
    let mobileNumber = this.mobileNumberTarget.value;
    let mobileNumberErrorMessage = this.mobileNumberErrorMessageTarget;
    let password = this.passwordTarget.value;
    let passwordErrorMessage = this.passwordErrorMessageTarget;
    let confirmPassword = this.confirmPasswordTarget.value;
    let confirmPasswordErrorMessage = this.confirmPasswordErrorMessageTarget;

    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(firstName, firstNameErrorMessage, 'First Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(lastName, lastNameErrorMessage, 'Last Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(email, emailErrorMessage, 'Email is required', is_valid);
    is_valid = this.validationIsFieldEmpty(mobileNumber, mobileNumberErrorMessage, 'Mobile number is required', is_valid);
    is_valid = this.validationIsFieldEmpty(password, passwordErrorMessage, 'Password is required', is_valid);

    if (!EMAIL_REGEX.test(email)) {
      this.showErrorMessage(emailErrorMessage, 'Invalid email');
      is_valid = false;
    }

    if (!(UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password) && DIGIT_REGEX.test(password) && password.length >= 8)) {
      this.passwordRegistrationValidation();
      is_valid = false;
    }

    if (password != confirmPassword) {
      confirmPasswordErrorMessage.textContent = 'Password and confirm password should match';
      is_valid = false;
    }

    this.validateEmailExistence();
    if (!this.is_email_valid) {
      is_valid = false;
    }

    this.validateMobileNumberExistence();
    if (!this.is_mobile_valid) {
      is_valid = false;
    }

    this.validateReferralCodeExistence();
    if (!this.is_referral_valid) {
      is_valid = false;
    }

    if (!is_valid) {
      e.preventDefault();
    }

  }

  login(e) {
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.loginEmailTarget.value, this.loginEmailErrorMessageTarget, 'Email is required to login', is_valid);
    is_valid = this.validationIsFieldEmpty(this.loginPasswordTarget.value, this.loginPasswordErrorMessageTarget, 'Password is required to login', is_valid);
    if (!is_valid) {
      e.preventDefault();
      return;
    }

    $.ajax({
      url: '/validate_user_credentials',
      method: 'POST',
      async: false,
      data: {
        email: this.loginEmailTarget.value,
        password: this.loginPasswordTarget.value
      },
      success: (response) => {
        if (response.response_code == 404) {
          this.loginEmailErrorMessageTarget.textContent = response.message;
          is_valid = false;
        } else if (response.response_code == 400) {
          this.loginPasswordErrorMessageTarget.textContent = response.message;
          is_valid = false;
        }
        if (!is_valid) {
          e.preventDefault();
        }
      }
    })

  }

}
