import { Controller } from "stimulus";
export default class extends Controller {
  connect() {
    console.log('connected');
  }

  //MealMenu Breakfast
  increaseMealMenuQuantity(e) {
    console.log("increaseMealMenuQuantity")
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenu${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityMealMenu(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseMealMenuQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity cannot be lesser than 1');
      //alert('Quantity cannot be lesser than 1');
      return;
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenu${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityMealMenu(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityMealMenu(quantity, originalPrice, id) {
    console.log("quantity", quantity)
    this.targets.find(`totalPriceMealMenu${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityMealMenu${id}`).textContent = quantity;
  }

  //MealMenu LunchDinner
  increaseMealMenuLunchDinnerQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuLunchDinner${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityMealMenuLunchDinner${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityMealMenuLunchDinner(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseMealMealMenuLunchDinnerQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealMenuLunchDinner${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return;
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuLunchDinner${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityMealMenuLunchDinner(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityMealMenuLunchDinner(quantity, originalPrice, id) {
    this.targets.find(`totalPriceMealMenuLunchDinner${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityMealMenuLunchDinner${id}`).textContent = quantity;
  }

  //**********MealMenu Keto**************
  increaseMealMenuKetoQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuKeto${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityMealMenuKeto${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityMealMenuKeto(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseMealMenuKetoQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealMenuKeto${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuKeto${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityMealMenuKeto(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityMealMenuKeto(quantity, originalPrice, id) {
    this.targets.find(`totalPriceMealMenuKeto${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityMealMenuKeto${id}`).textContent = quantity;
  }

  //**********MealMenu Snacks&drinks**************
  increaseMealMenuSnacksQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuSnacks${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityMealMenuSnacks${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityMealMenuSnacks(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseMealMenuSnacksQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealMenuSnacks${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealMenuSnacks${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityMealMenuSnacks(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityMealMenuSnacks(quantity, originalPrice, id) {
    this.targets.find(`totalPriceMealMenuSnacks${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityMealMenuSnacks${id}`).textContent = quantity;
  }

  //**********CustomMeal**************
  increaseCustomMealQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceCustomMeal${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityCustomMeal${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityCustomMeal(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseCustomMealQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityCustomMeal${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceCustomMeal${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityCustomMeal(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityCustomMeal(quantity, originalPrice, id) {
    this.targets.find(`totalPriceCustomMeal${id}`).textContent = originalPrice * quantity;
    this.targets.find(`quantityCustomMeal${id}`).textContent = quantity;
  }


  addToGuestCartMealMenu(e) {
    $.ajax({
      url: 'meal_menu_add_to_guest_cart',
      method: 'POST',
      data: {
        meal_menu_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.type}${e.target.dataset.id}`).textContent)
      },
      success: (response) => {
        if (response.response_code == 200) {
          console.log("cart_count:::===>", response.cart_count)
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          if (response.cart_count > 0) {
            $('#guest_cart_quantity_count').text(response.cart_count)
            $('#guest_cart_quantity_count_web').text(response.cart_count)
            $('#guest_cart_icon').show()
            $('#guest_login_icon').hide()
            $('#guest_cart_icon_web').show()
            $('#guest_login_icon_web').hide()
          } else {
            $('#guest_cart_icon').hide()
            $('#guest_login_icon').show()
            $('#guest_cart_icon_web').hide()
            $('#guest_login_icon_web').show()
          }
          toastr.success("Successfully added into the Cart")
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please login and increase the quantity")
        }
      }
    })
  }

  addToCartMealMenu(e) {
    $.ajax({
      url: 'meal_menu_add_to_cart',
      method: 'POST',
      data: {
        meal_menu_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.type}${e.target.dataset.id}`).textContent)

      },
      success: (response) => {
        if (response.response_code == 200) {
          console.log("cart_count:::===>", response.cart_count)
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          toastr.success("Successfully added into the Cart")
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please go to cart page and increase the quantity")
        }
      }
    })
  }

  //Start MealPackage Increase and Decrease quantity and Price
  increaseMealPackageQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealPackage${e.target.dataset.id}`).textContent);
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealPackage${e.target.dataset.id}`).textContent);
    console.log("originalPrice::" + originalPrice);
    quantity++;
    console.log("Quantity::" + quantity);
    this._updateTotalPriceQuantityMealPackage(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseMealPackageQuantity(e) {
    let quantity = parseInt(this.targets.find(`quantityMealPackage${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return
    }
    let originalPrice = parseFloat(this.targets.find(`originalPriceMealPackage${e.target.dataset.id}`).textContent);
    quantity--;
    this._updateTotalPriceQuantityMealPackage(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityMealPackage(quantity, originalPrice, id) {
    this.targets.find(`totalPriceMealPackage${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityMealPackage${id}`).textContent = quantity;
  }

  addToCartMealPackage(e) {
    console.log("MealPackage::" + e.target.dataset.id);
    $.ajax({
      url: 'meal_package_add_to_cart',
      method: 'POST',
      data: {
        meal_package_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityMealPackage${e.target.dataset.id}`).textContent)
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          toastr.success("Successfully added into the Cart")
          //window.location.href = `/cart_page`
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please go to cart page and increase the quantity")
        }
      }
    })
  }

  addToGuestCartMealPackage(e) {
    console.log("MealPackage::" + e.target.dataset.id);
    $.ajax({
      url: '/meal_package_add_to_guest_cart',
      method: 'POST',
      data: {
        meal_package_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityMealPackage${e.target.dataset.id}`).textContent)
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          toastr.success("Successfully added into the Cart")
          if (response.cart_count > 0) {
            $('#guest_cart_quantity_count').text(response.cart_count)
            $('#guest_cart_quantity_count_web').text(response.cart_count)
            $('#guest_cart_icon').show()
            $('#guest_login_icon').hide()
            $('#guest_cart_icon_web').show()
            $('#guest_login_icon_web').hide()
          } else {
            $('#guest_cart_icon').hide()
            $('#guest_login_icon').show()
            $('#guest_cart_icon_web').hide()
            $('#guest_login_icon_web').show()
          }
          //window.location.href = `/cart_page`
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please logn and increase the quantity")
        }
      }
    })
  }

  //Start LetsEatCard Increase and Decrease quantity and Price
  increaseLetsEatCardQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceLetsEatCard${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityLetsEatCard${e.target.dataset.id}`).textContent);
    console.log("quantity::", quantity, "originalPrice===>", originalPrice)
    quantity++;
    this._updateTotalPriceQuantityLetsEatCard(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseLetsEatCardQuantity(e) {
    let originalPrice = parseFloat(this.targets.find(`originalPriceLetsEatCard${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityLetsEatCard${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning("Quantity can not be lesser then 1")
      return
    }
    quantity--;
    this._updateTotalPriceQuantityLetsEatCard(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityLetsEatCard(quantity, originalPrice, id) {
    this.targets.find(`totalPriceLetsEatCard${id}`).textContent = (originalPrice * quantity).toFixed(2);
    this.targets.find(`quantityLetsEatCard${id}`).textContent = quantity;
  }

  addToCartMealCards(e) {
    console.log("MealPackage::" + e.target.dataset.id);
    $.ajax({
      url: 'meal_cards_add_to_cart',
      method: 'POST',
      data: {
        meal_card_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityLetsEatCard${e.target.dataset.id}`).textContent)
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          toastr.success("Successfully added into the Cart")
          //window.location.href = `/cart_page`
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please go to cart page and increase the quantity")
        }
      }
    })

  }

  addToGuestCartMealCards(e) {
    $.ajax({
      url: 'meal_cards_add_to_guest_cart',
      method: 'POST',
      data: {
        meal_card_id: e.target.dataset.id,
        quantity: parseInt(this.targets.find(`quantityLetsEatCard${e.target.dataset.id}`).textContent)
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          toastr.success("Successfully added into the Cart")
          if (response.cart_count > 0) {
            $('#guest_cart_quantity_count').text(response.cart_count)
            $('#guest_cart_quantity_count_web').text(response.cart_count)
            $('#guest_cart_icon').show()
            $('#guest_login_icon').hide()
            $('#guest_cart_icon_web').show()
            $('#guest_login_icon_web').hide()
          } else {
            $('#guest_cart_icon').hide()
            $('#guest_login_icon').show()
            $('#guest_cart_icon_web').hide()
            $('#guest_login_icon_web').show()
          }
          //window.location.href = `/cart_page`
        } else if (response.response_code == 400) {
          toastr.warning("Already added into the Cart, Please login and increase the quantity")
        }
      }
    })

  }

  delete_cart_items(e) {
    let cart_id = e.target.dataset.id
    $.ajax({
      url: `/carts/${cart_id}`,
      method: 'DELETE',
      data: {
        cart_id: e.target.dataset.id,
      },
      success: (response) => {
        if (response.response_code == 200) {
          toastr.success("Successfully deleted the cart item")
        }
      }
    })
  }

  //CART Page Increase and decrease quantity and price
  increaseCartPageQuantity(e) {
    console.log("INSIDE Quantity" + parseInt(this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent));
    console.log("INSIDE Original PRice" + parseInt(this.targets.find(`originalPriceCartPage${e.target.dataset.id}`).textContent));
    let originalPrice = parseFloat(this.targets.find(`originalPriceCartPage${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent);
    quantity++;
    this._updateTotalPriceQuantityCartPage(quantity, originalPrice, e.target.dataset.id)
  }

  decreaseCartPageQuantity(e) {
    console.log("INSIDE decreaseCartPageQuantity")
    let originalPrice = parseFloat(this.targets.find(`originalPriceCartPage${e.target.dataset.id}`).textContent);
    let quantity = parseInt(this.targets.find(`quantityCartPage${e.target.dataset.id}`).textContent);
    if (quantity <= 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return
    }
    quantity--;
    this._updateTotalPriceQuantityCartPage(quantity, originalPrice, e.target.dataset.id)
  }

  _updateTotalPriceQuantityCartPage(quantity, originalPrice, id) {
    this.targets.find(`totalPriceCartPage${id}`).textContent = originalPrice * quantity;
    this.targets.find(`quantityCartPage${id}`).textContent = quantity;
  }

}