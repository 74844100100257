import { Controller } from "stimulus";
export default class extends Controller{

  static targets = [
    'referralCode', 'referralLink'
  ]

  connect(){
    console.log('referral connected');
  }

  copyReferralCode(e){
    navigator.clipboard
    .writeText(this.referralCodeTarget.value)
    .then(() => {
      toastr.success('Successfully copied referal code to the clipboard');
    })
    .catch(() => {
      toastr.success('something went wrong')
    });

  }

  copyReferralLink(e){
    navigator.clipboard
    .writeText(this.referralLinkTarget.value)
    .then(() => {
      toastr.success('Successfully copied referal link to the clipboard');
    })
    .catch(() => {
      toastr.success('something went wrong')
    });
  }
}
