import { UserController } from "./user_controller"
// import Rails from "@rails/ujs";

export default class extends UserController {

  static targets = [
    'mobileNumber', 'mobileNumberErrorMessage', 'otp1',
    'otp2', 'otp3', 'otp4', 'otpVerifyDispay', 'triggerOTP',
    'retriggerTimer', 'time'
  ]

  counter = 60;

  connect(){
    console.log('profile mobile connected');
  }

  verifyMobileAndTriggerOtpProfile(e){
    console.log(this.mobileNumberTarget.value);
    let mobileNumber = this.mobileNumberTarget.value
    if (mobileNumber.length != 10) {
      this.mobileNumberErrorMessageTarget.textContent = 'Mobile Number is not Valid'
      return;
    }

    $(this.triggerOTPTarget).prop('disabled', true)
    $(this.triggerOTPTarget).html("<i class='fa fa-spinner fa-spin'></i> Triggering OTP...")
    $.ajax({
      url: '/verify_existence_trigger_otp',
      method: 'GET',
      data: {
        mobile_number: mobileNumber
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          toastr.success('Successfully triggered OTP')
          $(this.otpVerifyDispayTarget).show();
          $(this.triggerOTPTarget).hide();
          $(this.retriggerTimerTarget).show();
          this.counter = 60
          this.timer()
        }else{
          toastr.error(response.message)
        }
        $(this.triggerOTPTarget).prop('disabled', false)
        $(this.triggerOTPTarget).html("SEND OTP")
      }
    })
  }

  timer(){
    let interval = setInterval(() => {
      this.counter--;
      // Display 'counter' wherever you want to display it.
      if (this.counter <= 0) {
        clearInterval(interval);
        // $('#timer').html("<h3>Count down complete</h3>");
        $(this.retriggerTimerTarget).hide();
        this.triggerOTPTarget.textContent = 'RESEND OTP'
        $(this.triggerOTPTarget).show()
        return;
      } else {
        $('#time').text(this.counter);
        console.log("Timer --> " + this.counter);
      }
    }, 1000);
  }

  verifyOtpUpdateMobileNumber(e){
    e.preventDefault()
    if (this.otp1Target.value == '' || this.otp2Target.value == '' || this.otp3Target.value == '' || this.otp4Target.value == '' ) {
      this.otpErrorMessageTarget.textContent = 'Enter Valid 4 digit OTP'
      return
    }

    $('#verifyOTPNumber').prop('disabled', true)
    $('#verifyOTPNumber').html("<i class='fa fa-spinner fa-spin'></i> Verifying OTP...")

    $.ajax({
      url: '/verify_otp_update_mobile_number',
      method: 'GET',
      data: {
        mobile_number: this.mobileNumberTarget.value,
        otp: `${this.otp1Target.value}${this.otp2Target.value}${this.otp3Target.value}${this.otp4Target.value}`
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          location.reload()
        }else if(response.response_code == 406){
          this.otpErrorMessageTarget.textContent = response.message
          $('#verifyOTPNumber').prop('disabled', false)
          $('#verifyOTPNumber').html("Verify")
        }else{
          toastr.error(response.message)
        }
        $('#verifyOTPNumber').prop('disabled', false)
        $('#verifyOTPNumber').html("Verify")
      }
    })
  }

}