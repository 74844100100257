import { ErrorController } from "./error_controller.js"
import { Controller } from "stimulus";
import {STRIPE} from "../custom_constant"
export default class extends Controller{
  static targets = [
    'changeCard','deleteCard','orderDetailChangeCard'
  ]

  connect(){
    console.log('my account page connected');
  }

  changeCard(e){
    console.log('clicked');
    // e.preventDefault()

    $(e.srcElement).prop('disabled', true)
    $(e.srcElement).html("<i class='fa fa-spinner fa-spin'></i> Redirecting...")
    $.ajax({
      url: '/stripe_session_create',
      method: 'Get',
      headers: {
        'Content-Type': 'application/json',
      },
      success: response => {
        $(e.srcElement).html("Add new card")
        $(e.srcElement).prop('disabled', false)
        return STRIPE.redirectToCheckout({ sessionId: response.id });
      }
    }).fail( () => {
      toastr.error('Something went wrong, try again later!!')
      $(e.srcElement).html("Add new card")
      $(e.srcElement).prop('disabled', false)
    })
  }

  deleteCard(e){
    let user_card_id = e.target.dataset.id
    console.log('Delete card',user_card_id)
    $.ajax({
      url: `/delete_user_card/${user_card_id}`,
      method: 'Get',
      async: false,
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          toastr.success('Successfully deleted the User Card')
          location.reload();
          return;
        }else{
          toastr.error(response.message)
        }
      }
    })
  }

  orderDetailChangeCard(e){
    let order_id = e.target.dataset.id
    $.ajax({
      url: '/order_detail_stripe_session_create',
      method: 'Get',
      data: {
        order_id: order_id
      },
      headers: {
        'Content-Type': 'application/json',
      },
      success: response => {
        return STRIPE.redirectToCheckout({ sessionId: response.id});
      }
    })
  }
}  