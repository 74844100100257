import { Controller } from "stimulus";

export default class extends Controller{
  login(){
    $('#login_modal').modal('show');
    $('#register_modal').modal('hide');
  }

  customer_registration(){
    $('#login_modal').modal('hide');
    $('#register_modal').modal('show');
  }

}