const { subscribe } = require("toastr");
let STRIPE = Stripe(document.querySelector("meta[name='stripe_public_key']").content);
//let DOMAIN = document.querySelector("meta[name='domain']").content

// window.cart_purchase_type = e => {
//   console.log("PURCHASE TYPE")
//   event.preventDefault();
//   $(this).switchClass('am-form__switch-active');
// }

// window.onload = () =>{
//   console.log("yes")
//   status_on()
// }

// status_on = () =>{
//   $.ajax({
//     url: '/data_subscribe_on',
//     method: 'GET',
//     data: {

//     },
//     success: response => {
//       if (response.response_code == 200){
//         console.log(response)
//         // console.log(response.see)
//         // console.log(response.see.id)
//         console.log(response.idss)
//         response.idss.forEach(function(item) {
//           console.log(item)
//           $(`#${item}_subscription_auto_renewal`).prop("checked", true);

//       });

//       }
//     }
//   })
// }


window.subscribe = cart_id =>{
  let purchase_type =  $(`#${cart_id}_subscription_auto_renewal`).is(":checked")

  console.log(purchase_type)
  // $(`#${cart_id}_subscription_auto_renewal`).checked = purchase_type

  $(`#${cart_id}_subscription_auto_renewal`).checked = subscribe

  updatesubscribe(cart_id,purchase_type)
}

updatesubscribe = (meal_cart_id,sub_purchase_type) =>{
  let cart_id = meal_cart_id
  let purchase_type = sub_purchase_type
  console.log(cart_id)
  console.log(purchase_type)

  $.ajax({
		url: '/purchase_type_subs',
		method: 'GET',
		data: {
			cart_id: cart_id,
			purchase_type: purchase_type
		},
		success: response => {
			if (response.response_code == 200){
				console.log(response)
        // console.log(subscribe_status)
        // print(response.subscribe_status)
        // $(`#${cart_id}_subscription_auto_renewal`).checked = response.subscribe_status
        // print(response.subscribe_status)

			}
		}
	})
}




window.decreaseItemQuantity = cart_id => {
	let quantity = $(`#${cart_id}_quantity_cart_page`).text()
  quantity--;
  if (quantity < 1) {
    toastr.error('Quantity cannot be lesser than 1');
    return;
  }
  $(`#${cart_id}_quantity_cart_page`).text(quantity)
  updateTotalAmount();
  updateMealPrice(cart_id,quantity);
}

window.increaseItemQuantity = cart_id => {
	let quantity = $(`#${cart_id}_quantity_cart_page`).text()
  quantity++;
  $(`#${cart_id}_quantity_cart_page`).text(quantity)
  updateTotalAmount();
  updateMealPrice(cart_id,quantity);
}

updateMealPrice = (meal_cart_id,meal_quantity) => {
	console.log("INSIDE updateMealPrice::")
	let cart_id = meal_cart_id
	let quantity = meal_quantity
	$.ajax({
		url: '/calculate_meal_price',
		method: 'GET',
		data: {
			cart_id: cart_id,
			quantity: quantity
		},
		success: response => {
			if (response.response_code == 200){
				console.log(response)
				$(`#${cart_id}_meal_price`).html(`${response.meal_price.toFixed(2)}`)
			}
		}
	})
}

window.customerPickUpChoose = () => {
  pickup_address = $('input[name=delivery_type]:checked').val()
  if (pickup_address == 'pickup') {
    $('#deliveryAddressDisplay').hide();
    $('#pickUpAddressDisplay').show();
    $('#deliveryChargesDisplay').hide();
    //$('#proceed_to_pay').show();
    $('#proceed_to_pay').prop('disabled', false);
  }
  $('#delivery_pick_up_date').html('Pickup Date')
  updateTotalAmount();
}

window.changePickUpAddress = () => {
  $('#choosenPickUpFullAddress').css({"color": ""})
  let selectedAddressElement = $('input[name=pickup_address]:checked').val()
  $('#choosenPickUpAddressId').html(selectedAddressElement)
  $('#choosenPickUpFullAddress').html($(`#${selectedAddressElement}_pickUpAddresses_id`).html())
  $('#select_pickup_modal').modal('hide');
  if ($('#choosenPickUpAddressId').html() == '') {
    toastr.warning('Changed pick up location')
  } else {
    toastr.success('Changed pick up location')
  }
  updateTotalAmount();
}

window.deliveryAddressChoose = () => {
   $('#pickUpAddressDisplay').hide(200, () => {
    $('#deliveryAddressDisplay').show();
    $('#deliveryChargesDisplay').show();
    $('#delivery_pick_up_date').html('Delivery Date')
  })
  if ($('#choosenDeliveryFullAddress').text() == "You haven't added any Address!") {
    toastr.warning('Please add Address')
    //$('#proceed_to_pay').hide();
    // $('#proceed_to_pay').prop('disabled', true);
  }
  updateTotalAmount();
}

window.changeDeliveryAddress = () => {
  console.log("changeDeliveryAddress")
  let selectedAddressElement = $('input[name=delivery_address]:checked').val()
  $('#choosenDeliveryFullAddress').css({'color': ''})
  $('#choosenDeliveryAddressId').html(selectedAddressElement)
  $('#choosenDeliveryFullAddress').html($(`#${selectedAddressElement}_deliveryAddresses`).html())
  console.log(($(`#${selectedAddressElement}_deliveryAddresses`).html()))
  $('#change_address_modal').modal('hide');
  toastr.success('changed delivery address')
  updateTotalAmount();
}


showOtherAddressType = () => {
  $('#otherAddressTypeDiv').show(400);
}

hideOtherAddressType = () => {
  $('#otherAddressTypeDiv').hide(400);
}

window.saveAddress = (e) => {
    //e.preventDefault();
  let defaultAddressType = $('input[name=address_type]:checked').val()

  let make_default = $('#default').prop('checked')
  let address1 = $('#addressLine1').val()
  let address2 = $('#addressLine2').val()
  let other_address_type = $('#otherAddressTypeInput').val()

  let not_valid = false
  if (address1 == '') {
    $('#checkoutLine1InputErrorMessage').html('Address Line1 is required');
    not_valid = true
  }

  if ($('#savePinCode').val() == '') {
    $('#checkoutzipcodeInputErrorMessage').html('Zipcode is required');
    not_valid = true
  }

  if (defaultAddressType == 'Other' && other_address_type == '') {
    $('#otherAddressTypeInputErrorMessage').html('Address Other Type is requierd')
    not_valid = true
  }

  if (not_valid) {
      return false;
  }

  if (defaultAddressType == "Home") {
    defaultAddressType = 'Home'
  } else if (defaultAddressType == "Office") {
    defaultAddressType = 'Office'
  } else if (defaultAddressType == "Other" ) {
    defaultAddressType = 'Other'
    defaultAddressType =  $('#otherAddressTypeInput').val()
  }

  $('#saveNewAddress').prop('disabled', true)
  $('#saveNewAddress').html("<i class='fa fa-spinner fa-spin'></i> Adding address...")

  $.ajax({
    url: '/add_address_checkout',
    method: 'POST',
    async: false,
    data: {
      address_type: defaultAddressType,
      address_line1: $('#addressLine1').val(),
      address_line2: $('#addressLine2').val(),
      pincode: $('#savePinCode').val(),
      default: make_default,
      other_address_type: $('#otherAddressTypeInput').val()
    },
    success: (response) => {
      if (response.response_code == 200) {
        $('#proceed_to_pay').prop('disabled', false);
        $('#choosenDeliveryAddressId').html(response.address_id)
        $('#choosenDeliveryFullAddress').html(response.full_address)
        // $('#choosenDeliveryFullAddress').removeClass('text-danger')
        $('#choosenDeliveryFullAddress').css({'color': ''})
        // $('#default').prop('disabled', false)
        $("#default").prop('onclick', null);
        $('#default').prop('checked', false)

        let data = `
          <div class="col-md-6 address-count">
            <div class="am-global__address">
              <label class="am-form__checkbox-label d-flex flex-column align-items-start flex-wrap">
                <div class="am-form__checkbox-custom am-form__checkbox-custom--radio mb-1 d-flex align-items-start">
                  <div id='${response.address_id}_deliveryAddresses' style="display:none">${response.full_address}</div>
                  <input class="am-form__checkbox-input am-form__checkbox-input--radio" type="radio" name="delivery_address" value='${response.address_id}'  data-check-out-target="deliveryAddresses" data-id='${response.address_id}' data-address='${response.full_address}' checked >
                  <span class="am-form__checkbox-mark am-form__checkbox-mark--radio"></span>
                  <span class="am-form__checkbox-text am-form__checkbox-text--radio ps-6">${response.address_type}</span>
                </div>
                <p class="am-form__checkbox-desc text-sm text-start ms-6 mb-1 leading-tight">${response.full_address}</p>
              </label>
            </div>
          </div>
          `

        $('#createdAddress').append(data)
        clearCartAddressForm();
        $('#change_address_modal').modal('hide');
        $('#cart_add_address_show').hide()

        toastr.success(response.message)
        if ($('.address-count').length > 1) {
          $('#addAddressSelect').prop('disabled', false)
        }
        updateTotalAmount();
      }
      else if (response.response_code == 400) {
        toastr.warning(response.message)
      }
      $('#saveNewAddress').prop('disabled', false)
      $('#saveNewAddress').html("Save & select")
    }
  }).fail(() => {
    $('#saveNewAddress').prop('disabled', false)
    $('#saveNewAddress').html("Save & select")
    toastr.error('Something went wrongm, try again later!')
  })
  return false
  }

  clearCartAddressForm = () => {
    $('input[name=address_type]:checked').val('')
    $('#addressLine1').val('')
    $('#addressLine2').val('')
    $('#savePinCode').val('')
    $('#otherAddressTypeInput').val('')
    $('#otherAddressTypeDiv').hide();
  }

validationIsFieldEmpty = (input_field,error_div,message,is_valid) => {
	console.log(is_valid);
	$('input[type="text"]').each(function() {
    //if ($('#discountCouponInput').val() != "") {
    if (input_field != "") {
      is_valid = false;
      return false;
    }
    else{
    	error_div.html(message)
    	is_valid = true;
      return true;
    }
  });
}


window.discountCouponValidateApply = () => {
  //checking discountcoupon text box is empty ,if empty do not trigger ajax call
  var is_valid = true;
  validationIsFieldEmpty($('#discountCouponInput').val(),$('#discountCouponErrorMessage'),'Please enter Discount coupon code',is_valid);

  $.ajax(
    {
      url: "/validate_discount_code",
      method: "POST",
      async: false,
      data: { discount_code: $('#discountCouponInput').val() },
      success: response => {
        if (response.response_code == 200) {
        	console.log("APLLY Successfully")
          toastr.success(response.message)
          $('#discountCouponInput').prop('disabled', true);
          $('#discountCouponApplyButton').hide();
          $('#discountCouponRemoveButton').show();
          $('#discountCouponErrorMessage').hide();
          //$('#discountCouponErrorMessage').textContent = 'Successfully Applied Discount coupon!!'
          //toastr.success('Successfully Applied Discount coupon!!');
          $('#discountCodeCouponDisplayValue').innerHTML = $('#discountCouponInput').val();
          $('#discountCodeDisplay').show();
        } else {
          //toastr.error('Invalid Discount coupon')
          toastr.error(response.message)
          $('#discountCouponInput').prop('disabled', false);
          $('#discountCouponRemoveButton').hide();
          $('#discountCouponApplyButton').show();
          $('#discountCouponErrorMessage').textContent = response.message;
          $('#discountCodeDisplay').hide();
        }
      }
    }
  );
  updateTotalAmount();
}

window.discountCouponRemove = () => {
	console.log("REMOVE Discount Coupon");
  $('#discountCouponInput').prop('disabled', false);
  $('#discountCouponInput').val('');
  $('#discountCouponRemoveButton').hide();
  $('#discountCouponApplyButton').show();
  $('#discountCodeDisplay').hide();
  $('#discountCouponErrorMessage').hide();
  toastr.warning('Successfully removed applied discount coupon')
  updateTotalAmount();
}

window.applyCoins = () => {
  // console.log('changed');
  updateTotalAmount();
}

updateTotalAmount = () => {
  let MealsQuantityCartIds = getCartMeals();

  if (MealsQuantityCartIds.length == 0) {
    // location.reload();
    $('#empty_cart_warning_message').show();
    $('#complete_cart_warning_message').hide();
  }else{
    $('#empty_cart_warning_message').hide();
    $('#complete_cart_warning_message').show();
  }
  console.log(MealsQuantityCartIds)
  let discount_coupon;
  if ($('#discountCouponInput').prop('disabled')) {
    discount_coupon = $('#discountCouponInput').val();
  }

  let apply_coins = false
  if ($('#coins_enable').length == 1) {
    if ($('#coins_enable').prop('checked')) {
      apply_coins = true
    }else{
      apply_coins = false
    }
  }

  let loyalty_points = false
  if ($('#loyalty_points_enable').length == 1) {
    if ($('#loyalty_points_enable').prop('checked')) {
      loyalty_points = true
    }else{
      loyalty_points = false
    }
  }

  console.log("Loyalty Points", loyalty_points)

  let customer_pickup_id = '';
  let delivery_address_id = '';
  let delivery_charge = ''

  let selected_delivery_type = $('input[name=delivery_type]:checked').val()
  console.log("selectedAddressElement==>",selected_delivery_type)

  if (selected_delivery_type == "pickup") {
    customer_pickup_id = $('#choosenPickUpAddressId').text()
  } else {
    delivery_address_id = $('#choosenDeliveryAddressId').text()
    delivery_charge = $('#deliveryChargesDisplay').text();
  }

  console.log("delivery_address_id====>",delivery_address_id)
  console.log("discount_coupon::====>",discount_coupon)
  $.ajax({
    url: '/calculate_total_amount',
    method: 'GET',
    data: {
      cart_items: JSON.stringify(MealsQuantityCartIds),
      selected_delivery_type: selected_delivery_type,
      delivery_address_id: delivery_address_id,
      customer_pickup_id: customer_pickup_id,
      discount_coupon: discount_coupon,
      apply_coins: apply_coins,
      loyalty_points: loyalty_points
    },
    success: response => {
      if (response.response_code == 200) {
      	console.log(response);
        $('#subTotal').html(`${response.sub_total.toFixed(2)}`)
        $('#totalAmount1').html(response.total_amount.toFixed(2))
        $('#totalAmount').html(response.total_amount.toFixed(2))
        $('#discountAmountDisplay').html(`- $${response.discount_amount.toFixed(2)}`)
        $('#discountCodeCouponDisplayValue').html(discount_coupon)
        $('#deliveryCharge').html(response.delivery_charges.toFixed(2))
        $('#taxValueDisplay').html(response.tax_amount.toFixed(2))
        $('#display_coins_content').html(`
        You currently have ${response.user_coins.toFixed(2)} coins in your account. You can avail ${response.reducing_coins.toFixed(2)} coins to reduce the total amount.`)
        if (apply_coins) {
          $('#CoinsAppliedDisplay').show()
          $('#coinsReducedAmountLabel').html(`${response.reducing_coins.toFixed(2)} Coins Applied`)
          $('#coinsReducedAmount').html(response.reducing_coins.toFixed(2))

        }else{
          $('#CoinsAppliedDisplay').hide()
        }

        if (loyalty_points) {
          $('#PointsAppliedDisplay').show()
          $('#pointsReducedAmountLabel').html(`${response.reducing_points.toFixed(2)} Points Applied`)
          $('#pointsReducedAmount').html(response.reducing_points.toFixed(2))

        }else{
          $('#PointsAppliedDisplay').hide()
        }
      }
    }
  })
}

getCartMeals = () => {
  let cartItems = $('.cartIds')
	console.log("cartItems" + "-" + cartItems)
	let cart_items_quantity = cartItems.map((index,element) => {
    console.log("special_instruction=============>", $(`#${element.innerHTML}_special_instruction`).text())
		return {
      cart_id: element.innerHTML,
      quantity: $(`#${element.innerHTML}_quantity_cart_page`).text(),
      special_instruction: $(`#${element.innerHTML}_special_instruction`).val(),
      subscription_auto_renewal: $(`#${element.innerHTML}_subscription_auto_renewal`).prop('checked')
    }
	}).get()
  console.log("special_instruction",cart_items_quantity)
	return(cart_items_quantity)
}

window.delete_cart_items = delete_cart_id => {
  let cart_id = delete_cart_id
  $.ajax({
    url: `/checkout/${cart_id}`,
    method: 'DELETE',
    data: {
      cart_id: cart_id
    },
    success: (response) => {
      if (response.response_code == 200) {
        toastr.success('Successfully deleted the cart item')
        $(`#cart_page_cart_item_${delete_cart_id}`).remove();
        updateTotalAmount();
      }
    }
  })
  return false;
}

window.proceedToCheckout = e => {

  if ($('input[name=delivery_type]:checked').val() == "pickup") {
    if ($('#choosenPickUpAddressId').text() == '') {
      toastr.error('Please choose a pickup or delivery location for your meals to be delivered')
      return
    }
  }else{
    if ($('#choosenDeliveryAddressId').text() == '') {
      toastr.error('Please choose a pickup or delivery location for your meals to be delivered')
      return
    }
  }

  if ($('input[name=delivery_type]:checked').val() == "delivery" && $('#choosenDeliveryAddressId').text() == ''){
    toastr.error('Add address for home delivery');
    return;
  }

  if (parseFloat($('#totalAmount1').html()) == 0){
    $('#proceed_to_pay').prop('disabled', true)
    $('#proceed_to_pay').html("<i class='fa fa-spinner fa-spin'></i> Placing Order...")
    orderCheckout()
  }else if(parseFloat($('#totalAmount1').html()) < 1){
    toastr.error('Total amount should be grater than a dollar or free')
  }else{
    $('#select_saved_card').modal('show')
  }
}

window.orderCheckout = () => {
  // console.log('working');
  $('#checkOutOrderWithExistingCard').prop('disabled', true)
  $('#checkOutOrderWithExistingCard').html("<i class='fa fa-spinner fa-spin'></i> Placing Order...")
  let CartIdsMealsQuantity = getCartMeals();
  console.log("orderCheckout=========>",CartIdsMealsQuantity)
  let discount_coupon;
  if ($('#discountCouponInput').prop('disabled',false)) {
    discount_coupon = $('#discountCouponInput').val();
  }

  let customer_pickup_id = '';
  let delivery_address_id = '';
  // let delivery_charge = ''

  let selected_delivery_type = $('input[name=delivery_type]:checked').val()
  console.log("selectedAddressElement==>",selected_delivery_type)

  if (selected_delivery_type == "pickup") {
    if ($('#choosenPickUpAddressId').text() == '') {
      toastr.error('Please choose a pickup or delivery location for your meals to be delivered')
      return
    }
    customer_pickup_id = $('#choosenPickUpAddressId').text()
  } else {
    delivery_address_id = $('#choosenDeliveryAddressId').text()
    // delivery_charge = $('#deliveryChargesDisplay').text();
  }

  let selected_user_card_id = $('input[name=saved_cards]:checked').val()

  let apply_coins = false
  if ($('#coins_enable').length == 1) {
    if ($('#coins_enable').prop('checked')) {
      apply_coins = true
    }else{
      apply_coins = false
    }
  }

  let loyalty_points = false
  if ($('#loyalty_points_enable').length == 1) {
    if ($('#loyalty_points_enable').prop('checked')) {
      loyalty_points = true
    }else{
      loyalty_points = false
    }
  }

  $.ajax({
    url: '/checkout_all_orders',
    method: 'POST',
    data: {
      cart_items: JSON.stringify(CartIdsMealsQuantity),
      discount_coupon: discount_coupon,
      delivery_address_id: delivery_address_id,
      customer_pickup_id: customer_pickup_id,
      selected_user_card_id: selected_user_card_id,
      apply_coins: apply_coins,
      loyalty_points: loyalty_points
    },
    success: function(result){
      if (result.response_code == 200) {
        toastr.success('Transaction is Successful!')
        window.location.href = `/transaction_success_page?&order_id=${result.order_id}&message=${result.message}`
      } else if (result.response_code == 403){
        toastr.warning('Transaction Failed.')
        window.location.href = `/transaction_failure_page?&order_id=${result.order_id}&message=${result.message}`
      }else if ( result.response_code == 406){
        toastr.warning(result.message)
        $('#checkOutOrderWithExistingCard').html("Checkout")
        $('#checkOutOrderWithExistingCard').prop('disabled', false)
      }
    }
  }).fail( () => {
    toastr.error('Something went wrong, try again later!!')
    $('#checkOutOrderWithExistingCard').html("Checkout")
    $('#checkOutOrderWithExistingCard').prop('disabled', false)
  })
  return false
}

window.NewCardCheckout = () => {
  $('#AddNewCardCheckout').prop('disabled', true)
  $('#AddNewCardCheckout').html("<i class='fa fa-spinner fa-spin'></i> Redirecting...")
  let CartIdsMealsQuantity = getCartMeals();

  let discount_coupon;
  if ($('#discountCouponInput').prop('disabled',false)) {
    discount_coupon = $('#discountCouponInput').val();
  }

  let customer_pickup_id = '';
  let delivery_address_id = '';
  let delivery_charge = ''

  let apply_coins = false
  if ($('#coins_enable').length == 1) {
    if ($('#coins_enable').prop('checked')) {
      apply_coins = true
    }else{
      apply_coins = false
    }
  }

  let selected_delivery_type = $('input[name=delivery_type]:checked').val()
  console.log("selectedAddressElement==>",selected_delivery_type)

  if (selected_delivery_type == "pickup") {
    customer_pickup_id = $('#choosenPickUpAddressId').text()
  } else {
    delivery_address_id = $('#choosenDeliveryAddressId').text()
    delivery_charge = $('#deliveryChargesDisplay').text();
  }

  $.ajax({
    url: '/new_card_stripe_session_create',
    method: 'POST',
    data: {
      cart_items: JSON.stringify(CartIdsMealsQuantity),
      discount_coupon: discount_coupon,
      delivery_address_id: delivery_address_id,
      customer_pickup_id: customer_pickup_id,
      apply_coins: apply_coins
      //selected_user_card_id: selected_user_card_id
    },
    success: response => {
      if (response.response_code == 200) {
        return STRIPE.redirectToCheckout({ sessionId: response.id });
      }else{
        toastr.warning(response.message)
        $('#AddNewCardCheckout').html("Add new card & checkout")
        $('#AddNewCardCheckout').prop('disabled', false)
      }
    }
  }).fail( () => {
    toastr.error('Something went wrong, try again later!!')
    $('#AddNewCardCheckout').html("Add new card & checkout")
    $('#AddNewCardCheckout').prop('disabled', false)
  });
}

//*******pause Subscription*******
window.modify_subscription = (sub_order_id) => {
  console.log("sub_order_id========>",sub_order_id);
  $('#pause_weeks_sub_order_id').html(sub_order_id)
  $(`#order_modify_subscription_${sub_order_id}`).modal('show');
}

window.open_confirmation_subscription_madal = (sub_order_id) => {
  console.log('open_confirmation_subscription_madal===>',sub_order_id)
  $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
  $('#pause_confirmation_sub_order_id').val(sub_order_id)
  selected_week = $('#pause_subscription option:selected').text()
  var data = '<p class="am-global__entry-para mb-8">Your Subscription will be paused for '+ selected_week +'. You can comeback at anytime and continue subscription at your convenience.</p>'
  $('#pause_confirmation').html(data)
  $('#order_confirm_pause').modal('show');
}

window.subscription_pause = () => {
  sub_order_id = $('#pause_weeks_sub_order_id').html()
  selected_week = $('#pause_subscription option:selected').text()
  selected_week_value = $('#pause_subscription option:selected').val()

  $.ajax({
    url: '/get_pause_subscription',
    method: 'GET',
    data: {
      selected_week: selected_week,
      selected_week_value: selected_week_value,
      sub_order_id: sub_order_id
    },
    success: response => {
      if (response.response_code == 200) {
        toastr.success('Subscription Paused Successfully')
        location.reload();
        $('#pause_duration').hide();
        $('#subscriptionResume').show();
        $('#modified').html(response.pause_subscription_date);
        $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
      }
    }
  });
}

window.open_resume_confirmation_madal = (sub_order_id) => {
  $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
  $('#resume_confirmation_sub_order_id').val(sub_order_id)
  $('#order_confirm_resume').modal('show');
}

window.subscription_resume = () => {
  //sub_order_id = $('#pause_weeks_sub_order_id').html()
  sub_order_id = $('#resume_confirmation_sub_order_id').val();
  console.log("sub_order_id====>",sub_order_id);
  $.ajax({
    url: '/get_resume_subscription',
    method: 'GET',
    data: {
      sub_order_id: sub_order_id
    },
    success: response => {
      if (response.response_code == 200) {
        toastr.success("Subscription Resumed Successfully")
        $('#subscriptionResume').hide();
        $('#subscriptionPause').show();
        $('#modified').hide();
        $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
        $('#order_confirm_resume').modal('hide')
        location.reload();
      }
    }
  });
}

window.open_confirmation_cancel_modal = (sub_order_id) => {
  $('#cancel_confirmation_sub_order_id').val(sub_order_id)
  $('#order_confirm_cancel').modal('show');
  $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
}

window.subscription_cancel = () => {
  sub_order_id = $('#cancel_confirmation_sub_order_id').val()
  $.ajax({
    url: '/get_cancel_subscription',
    method: 'GET',
    data: {
      sub_order_id: sub_order_id
    },
    success: response => {
      if (response.response_code == 200) {
        toastr.success('Subscription Cancelled Successfully');
        location.reload();
        $(`#order_modify_subscription_${sub_order_id}`).modal('hide');
      }
    }
  });
}
