const email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
window.get_location = (location_id) => {  
  var location_id = location_id.value;
  $.ajax({
    method: "GET",
    url: "/fetch_location_based_meals",
    data: {
      location_id: location_id
    },
    success: function(result){
    	if (result.response_code == 200) {
      	$('#full_address').html(result.full_address);
        let availability_hour = ''
        if (Array.isArray(result.hour)) {
          result.hour.forEach(function(hour){
            let days_hrs = hour.split(':-')
            availability_hour += `<b>${days_hrs[0]}</b>: ${days_hrs[1]}<br/>`
          })
        }else{
          availability_hour = '<b>24/7</b>'
        }
        $('#availability_hours').html(availability_hour);
        let meal_data = ''
        let quantity = 0
        let price = ''
        console.log("MEALS::---->",result.meals)
        result.meals.forEach(function(meal){
          quantity +=  meal.quantity
          // price +=  meal.price 
          var micronutrient_name_quantity = ''
          meal.micronutrients.forEach(function(micronutrient){
            console.log("micronutrients:::::" + micronutrient.micronutrient_name)  
            micronutrient_name_quantity += micronutrient.micronutrient_name + " : " + micronutrient.micronutrient_quantity + ' | '
          });

          meal_data += '<tr><td><div class="am-cart__item-info d-flex align-items-center"><div class="am-cart__item-img"><img class="img-fluid d-block" src="' + meal.image + '" alt="'+ meal.name +'" /></div><div class="am-cart__item-desc leading-none ms-2"><h6 class="am-cart__item-title text-capitalize">'+ meal.name +'</h6></div></div></td><td><div class="am-cart__item-ingredients d-inline-flex align-items-center flex-wrap">'+ micronutrient_name_quantity.slice(0, -2) +' </div></td><td><div class="am-cart__item-price">$'+ meal.price.toFixed(2) +'</div></td><td><div class="am-cart__item-quantity">'+meal.quantity+'</div></td></tr>'
        });
        $('#no_of_meals').html(quantity);
        micronutrient_name_quantity = '';
        $('#meals_body').html(meal_data)
    	}	else if (result.response_code == 400){
        no_meal_data = '<tr><td colspan="10" style="text-align:center; color:#979797;font-size:18px">'+ result.message +'</td></tr>'
        $('#meals_body').html(no_meal_data);
      }
    }
  });
};

window.newsletter_submit = () => {
  //$('#newsletter_submit').on('click', function(){

  let valid = true;
  if ($('#newsletter_email').val() == '') {
    show_error_with_id('newsletter_error_message', 'Enter your email id to subscribe for latest news and updates');
  }

  if ($('#newsletter_email').val() != '' && !email_regex.test($('#newsletter_email').val())) {
    show_error_with_id('newsletter_error_message', 'Email is not valid');
    valid = false;
  }

  if (valid) {
    $.ajax({
      method: "GET",
      url: "/news_letter",
      data: {
        email: $('#newsletter_email').val()
      },
    
      success: function(result){
        if (result.response_code == 200) {
          toastr.success('Thank you for subscribing to our newsletter');
          $('#newsletter_email').val('');
          $('#newsletter_error_message').hide();
        }else{
          toastr.error(result.message);
          //$('#newsletter_error_message').hide();
        }
      }
    })
  }
};

function show_error_with_id(id, message){
  $('#' + id).show();
  $('#' + id).html(message);
}

function hide_error_with_id(id){
  $('#' + id).hide();
}

