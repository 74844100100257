const { hide } = require("@popperjs/core");

$(document).ready(function () {
  increaseRedeemMealsQuantity = (index, grab_and_go_meal_id) => {
    console.log('clicked');
    let quantity = parseInt($(`#quantity_${index}_${grab_and_go_meal_id}`).html())
    quantity++;
    console.log(quantity);
    $(`#quantity_${index}_${grab_and_go_meal_id}`).html(quantity)
    // $(`#grab_and_go_quantity_${index}_${grab_and_go_meal_id}`).val(quantity)
  }

  decreaseRedeemMealsQuantity = (index, grab_and_go_meal_id) => {
    let quantity = parseInt($(`#quantity_${index}_${grab_and_go_meal_id}`).html())
    quantity--
    if (quantity < 1) {
      toastr.error('Quantity cannot be lesser than 1');
      return;
    }
    $(`#quantity_${index}_${grab_and_go_meal_id}`).html(quantity)
    // $(`#grab_and_go_quantity_${index}_${grab_and_go_meal_id}`).val(quantity)
  }

  closeRedeemingWarning = () => {
    $('#recharge_change_of_card_display').css('display','none');
    $('#recharge_change_of_card_display').hide().css('display', 'none');
  }

  order = (index, grab_and_go_meal_id) => {
    let redeem_meals = JSON.parse($('#redeem_meals').val())
    redeem_meals[grab_and_go_meal_id] = 0
    var addedMealsCountToRedeem = sumOfObjects(redeem_meals);
    console.log(addedMealsCountToRedeem);

    let availableMealToRedeem = parseInt($('#available_meal_to_redeem').html())
    let mealQuantityToRedeem = parseInt($(`#quantity_${index}_${grab_and_go_meal_id}`).html())
    if ((addedMealsCountToRedeem + mealQuantityToRedeem) > availableMealToRedeem) {
      console.log(`Available meals is ${availableMealToRedeem}, recharge to redeem more`);
      $('#recharge_change_of_card_display').css('display','none');
      $('#recharge_change_of_card_display').show().css('display','flex');
      $('#display_meal_available_and_redeemed').html(`Meals are exceeding available ${availableMealToRedeem} meals to redeem. Recharge Card Now!`)
      toastr.error(`Meals are exceeding available ${availableMealToRedeem} meals to redeem`)
      return
    }
    let quantity = addedMealsCountToRedeem + mealQuantityToRedeem
    $('#meals_count_to_redeem').html(quantity)

    // let grab_and_go_meal = `<input type="hidden" name="grab_and_go_id_${index}" value="${grab_and_go_meal_id}"> id=""`
    // let gran_and_go_quantity = `<input type="hidden" name="grab_and_go_quantity_${index}" value="${quantity}"> id="grab_and_go_quantity_"`
    // $('#inputs').append(grab_and_go_meal)
    // $('#inputs').append(gran_and_go_quantity)

    redeem_meals[grab_and_go_meal_id] = mealQuantityToRedeem
    $('#redeem_meals').val(JSON.stringify(redeem_meals))
    //console.log('Successfully added to redeem')
    toastr.success('meal added to Redeem')
    // re
  }

  function sumOfObjects(obj) {
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el]);
      }
    }
    return sum;
  }

  changeCardRedeemMeals = (user_meal_card_id, card_complete_code, element) => {
    $('#redeem_card_id').val(user_meal_card_id)

    console.log($(`#redeem_meals_pop_up_available_meals_${user_meal_card_id}`).html());
    $('#available_meal_to_redeem').html($(`#redeem_meals_pop_up_available_meals_${user_meal_card_id}`).html())
    $('#cardCompleteCode').html(card_complete_code)
    toastr.success('Successfully changed redemption card')
    $('.am-meal__card-choose--selected')[0].classList.remove('am-meal__card-choose--selected')
    element.classList.add('am-meal__card-choose--selected')
    return false
  }

  // $('#recharge_lets_eat_card').on('load', (e) => {
  //   console.log('loaded');
  // })

  fetchRechargeAmount = () => {
    $.ajax({
      url: '/fetch-amount-for-recharge',
      method: 'get',
      data: {
        redeem_card_id: $('#redeem_card_id').val()
      },
      success: (response) => {
        if (response.response_code == 200) {
          $('#redeem_meal_sub_price').html(`$${response.sub_price.toFixed(2)}`)
          $('#redeem_meal_tax').html(`$${response.tax.toFixed(2)}`)
          $('#redeem_meal_total_amount').html(`$${response.total_amount}`)
        }
      }
    })
  }

  refreshRedemptionCode = (redeemable_card_id) => {
    console.log(redeemable_card_id);
    $.ajax({
      url: `/refresh_redemption_code/${redeemable_card_id}`,
      method: 'GET',
      success: (response) => {
        if (response.response_code == 200) {
          $('#redemption_code_display').html(response.redemption_code)
        } else {
          console.log('something went wrong, please try later!!');
        }
      }
    })
  }

});