import { ErrorController } from "./error_controller.js"
export default class extends ErrorController {

  static targets = [
    'customMealSizeOptions', 'proteinSelect', 'totalMicronutrients', 'totalPriceMobile', 'quantity', 'proteinPortionErrorMessage',
    'totalPriceDesktop', 'proteinId', 'customMealProteinCategories'
  ]

  connect() {
    console.log('connected');
  }

  addToCart() {
    let proteinSizeText = $(this.proteinSelectTarget).find(':selected').text()
    if (proteinSizeText == 'None Selected') {
      this.proteinPortionErrorMessageTarget.textContent = 'Select protin size inorder to complete the order'
      return
    } else {
      this.proteinPortionErrorMessageTarget.textContent = ''
    }
    let is_error = false

    let categoryIds = this.customMealProteinCategoriesTargets
    let proteinId = this.proteinIdTarget
    let selectedCustomMealCategory = []

    categoryIds.forEach(function (element) {
      element.dataset.customMealCategoryId
      let category_types = this.targets.findAll(`${proteinId.textContent}_${element.dataset.customMealCategoryId}_category_images`)
      let selectedType = category_types.find(element => element.classList.contains('custom-meal-selected'))
      if (selectedType.dataset.title == 'None Selected') {
        return
      }
      let selectedOptionSize = $(`#custom_item_select_${proteinId.textContent}_${selectedType.dataset.customMealCategoryTypeId}`).val()
      if (selectedOptionSize != 'None Selected') {
        selectedCustomMealCategory.push({
          custom_meal_category_type_id: selectedType.dataset.customMealCategoryTypeId,
          custom_meal_category_size_id: selectedOptionSize
        })
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = ''
      } else {
        is_error = true
        toastr.error('Please select portion size')
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = 'Please select portion size'
      }
    }, this);
    console.log(is_error);
    if (is_error) {
      return
    }
    $.ajax({
      url: '/custom_meal_add_to_cart',
      method: 'GET',
      data: {
        custom_meals: JSON.stringify(selectedCustomMealCategory),
        custom_protein: {
          protein_id: this.proteinSelectTarget.dataset.proteinId,
          size_id: this.proteinSelectTarget.value
        },
        quantity: this.quantityTarget.textContent
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          console.log("Response::=====>", response)
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          $(`#custom_meals_modal${this.proteinSelectTarget.dataset.proteinId}`).modal('hide');
          toastr.success('Successfully added into the Cart')
          //window.location.href = `/cart_page`
        }
      }
    })
  }

  addToGuestCart() {
    let proteinSizeText = $(this.proteinSelectTarget).find(':selected').text()
    if (proteinSizeText == 'None Selected') {
      this.proteinPortionErrorMessageTarget.textContent = 'Select protin size inorder to complete the order'
      return
    } else {
      this.proteinPortionErrorMessageTarget.textContent = ''
    }
    let is_error = false

    let categoryIds = this.customMealProteinCategoriesTargets
    let proteinId = this.proteinIdTarget
    let selectedCustomMealCategory = []

    categoryIds.forEach(function (element) {
      element.dataset.customMealCategoryId
      let category_types = this.targets.findAll(`${proteinId.textContent}_${element.dataset.customMealCategoryId}_category_images`)
      let selectedType = category_types.find(element => element.classList.contains('custom-meal-selected'))
      if (selectedType.dataset.title == 'None Selected') {
        return
      }
      let selectedOptionSize = $(`#custom_item_select_${proteinId.textContent}_${selectedType.dataset.customMealCategoryTypeId}`).val()
      if (selectedOptionSize != 'None Selected') {
        selectedCustomMealCategory.push({
          custom_meal_category_type_id: selectedType.dataset.customMealCategoryTypeId,
          custom_meal_category_size_id: selectedOptionSize
        })
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = ''
      } else {
        is_error = true
        toastr.error('Please select portion size')
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = 'Please select portion size'
      }
    }, this);
    console.log(is_error);
    if (is_error) {
      return
    }
    $.ajax({
      url: '/custom_meal_add_to_guest_cart',
      method: 'GET',
      data: {
        custom_meals: JSON.stringify(selectedCustomMealCategory),
        custom_protein: {
          protein_id: this.proteinSelectTarget.dataset.proteinId,
          size_id: this.proteinSelectTarget.value
        },
        quantity: this.quantityTarget.textContent
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          console.log("Response::=====>", response)
          $('#cart_count_web').html(response.cart_count);
          $('#cart_count').html(response.cart_count);
          $(`#custom_meals_modal${this.proteinSelectTarget.dataset.proteinId}`).modal('hide');
          toastr.success('Successfully added into the Cart')
          if (response.cart_count > 0) {
            $('#guest_cart_quantity_count').text(response.cart_count)
            $('#guest_cart_quantity_count_web').text(response.cart_count)
            $('#guest_cart_icon').show()
            $('#guest_login_icon').hide()
            $('#guest_cart_icon_web').show()
            $('#guest_login_icon_web').hide()
          } else {
            $('#guest_cart_icon').hide()
            $('#guest_login_icon').show()
            $('#guest_cart_icon_web').hide()
            $('#guest_login_icon_web').show()
          }
          //window.location.href = `/cart_page`
        }
      }
    })
  }

  increaseQuantity() {
    let quantity = parseInt(this.quantityTarget.textContent);
    quantity++;
    this.quantityTarget.textContent = quantity
    this.updateMacronutrients();
  }

  decreaseQuantity() {
    let quantity = parseInt(this.quantityTarget.textContent);
    quantity--;
    if (quantity < 1) {
      toastr.warning('Quantity can not be lesser then 1')
      return;
    }
    this.quantityTarget.textContent = quantity
    this.updateMacronutrients();
  }

  changeType(e) {
    console.log('clicked');
    let category_types = this.targets.findAll(`${e.target.dataset.proteinId}_${e.target.dataset.customMealCategoryId}_category_images`)
    category_types.forEach(element => {
      element.classList.remove('custom-meal-selected');
    });
    e.target.classList.add('custom-meal-selected');

    let category_sizea = this.targets.findAll(`custom_item_select_${e.target.dataset.proteinId}_${e.target.dataset.customMealCategoryId}`)
    category_sizea.forEach(element => {
      // element.classList.remove('custom-meal-selected');
      // if (element.dataset) {

      // }
      $(element).hide();
    });
    $(`#custom_item_select_${e.target.dataset.proteinId}_${e.target.dataset.customMealCategoryTypeId}`).show()
    if ($(`#custom_item_select_${e.target.dataset.customMealCategoryTypeId}_${e.target.dataset.proteinId}`).val() != 'None Selected') {
      this.updateMacronutrients();
      this.updateParticularMacronutrient(e.target.dataset.customMealCategoryTypeId, $(`#custom_item_select_${e.target.dataset.proteinId}_${e.target.dataset.customMealCategoryTypeId}`).val(), e.target.dataset.proteinId, e.target.dataset.customMealCategoryId);
    } else {

    }
  }

  selectOptionChangeForProtein(e) {
    console.log(e.target.dataset.proteinId);
    $.ajax({
      url: '/custom_meal_category_macronutrient',
      method: 'GET',
      data: {
        custom_meal_category_type_id: e.target.dataset.proteinId,
        custom_meal_category_size_id: e.target.value
      },
      success: response => {
        if (response.response_code == 200) {
          this.targets.find(`macronutrient_protein_${e.target.dataset.proteinId}`).textContent = response.macronutrients
        }
      }
    })
    this.updateMacronutrients();
  }

  selectOptionChange(e) {
    let category_types = this.targets.findAll(`${e.target.dataset.proteinId}_${e.target.dataset.customMealCategoryId}_category_images`)
    let selectedOption = category_types.find(element => element.classList.contains('custom-meal-selected'))
    this.updateParticularMacronutrient(selectedOption.dataset.customMealCategoryTypeId, e.target.value, e.target.dataset.proteinId, e.target.dataset.customMealCategoryId)
    this.updateMacronutrients();
  }

  updateParticularMacronutrient(custom_meal_category_type_id, custom_meal_category_size_id, protein_id, custom_meal_category_id) {
    $.ajax({
      url: '/custom_meal_category_macronutrient',
      method: 'GET',
      data: {
        custom_meal_category_type_id: custom_meal_category_type_id,
        custom_meal_category_size_id: custom_meal_category_size_id
      },
      success: response => {
        if (response.response_code == 200) {
          this.targets.find(`macronutrient_${protein_id}_${custom_meal_category_id}`).textContent = response.macronutrients
        }
      }
    })
  }

  updateMacronutrients() {
    let proteinSizeText = $(this.proteinSelectTarget).find(':selected').text()
    if (proteinSizeText == 'None Selected') {
      this.proteinPortionErrorMessageTarget.textContent = 'Select protin size inorder to complete the order'
      return
    } else {
      this.proteinPortionErrorMessageTarget.textContent = ''
    }
    let categoryIds = this.customMealProteinCategoriesTargets
    let proteinId = this.proteinIdTarget
    let selectedCustomMealCategory = []

    categoryIds.forEach(function (element) {
      // category id
      element.dataset.customMealCategoryId
      let category_types = this.targets.findAll(`${proteinId.textContent}_${element.dataset.customMealCategoryId}_category_images`)
      let selectedType = category_types.find(element => element.classList.contains('custom-meal-selected'))
      if (selectedType.dataset.title == 'None Selected') {
        return
      }
      let selectedOptionSize = $(`#custom_item_select_${proteinId.textContent}_${selectedType.dataset.customMealCategoryTypeId}`).val()
      if (selectedOptionSize != 'None Selected') {
        selectedCustomMealCategory.push({
          custom_meal_category_type_id: selectedType.dataset.customMealCategoryTypeId,
          custom_meal_category_size_id: selectedOptionSize
        })
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = ''
      } else {
        this.targets.find(`${element.dataset.customMealCategoryId}_category_error`).textContent = 'Please select portion size'
      }

    }, this);
    $.ajax({
      url: '/macronutrients_total_calculations',
      method: 'GET',
      data: {
        custom_meals: JSON.stringify(selectedCustomMealCategory),
        custom_protein: {
          protein_id: proteinId.textContent,
          size_id: this.proteinSelectTarget.value
        },
        quantity: this.quantityTarget.textContent
      },
      success: response => {
        console.log(response);
        if (response.response_code == 200) {
          this.totalMicronutrientsTarget.textContent = response.macronutrients
          this.totalPriceMobileTarget.textContent = response.total_price.toFixed(2)
          this.totalPriceDesktopTarget.textContent = response.total_price.toFixed(2)
        }
      }
    })
  }
}
