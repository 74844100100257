import { UserController } from "./user_controller"
import {STRIPE} from "../custom_constant"
export default class extends UserController {
  static targets = [
    'profileFirstLastNameDisplay', 'profileFirstLastNameInput',
    'profileEmailDisplay', 'profileEmailInput',
    'profileFirstName', 'profileFirstNameErrorMessage',
    'profileLastName', 'profileLastNameErrorMessage', 'profileEdit',
    'profileSaveCancel'
  ]

  connect() {
    this.initializeVariables();
    this.disableProfileEdit();
  }

  initializeVariables(){
    this.firstName = this.profileFirstNameTarget.value;
    this.lastName = this.profileLastNameTarget.value;
    this.email = this.emailTarget.value;
  }

  enableProfileEdit(){
    $(this.profileFirstLastNameDisplayTarget).addClass('am-status__show-animate').hide( ()=>{
      $(this.profileFirstLastNameInputTarget).addClass('am-status__show-animate').show();
      this.profileFirstNameTarget.value = this.firstName;
      this.profileLastNameTarget.value = this.lastName;
    });
    $(this.profileEmailDisplayTarget).addClass('am-status__show-animate').hide( () => {
      $(this.profileEmailInputTarget).addClass('am-status__show-animate').show();
      this.emailTarget.value = this.email;
    });
    $(this.profileEditTarget).addClass('am-status__show-animate').hide( () => {
      $(this.profileSaveCancelTarget).addClass('am-status__show-animate').show();
    });
  }

  disableProfileEdit(){
    $(this.profileFirstLastNameInputTarget).addClass('am-status__show-animate').hide( () => {
      $(this.profileFirstLastNameDisplayTarget).addClass('am-status__show-animate').show();
    });
    $(this.profileEmailInputTarget).addClass('am-status__show-animate').hide( () => {
      $(this.profileEmailDisplayTarget).addClass('am-status__show-animate').show();
    });
    $(this.profileSaveCancelTarget).addClass('am-status__show-animate').hide( () => {
      $(this.profileEditTarget).addClass('am-status__show-animate').show();
    });
  }

  mobileNumberOnKeypressValidation(e){
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      this.profileMobileNumberErrorMessageTarget.textContent = 'Contact has only number'
      e.preventDefault();
    }else{
     this.profileMobileNumberErrorMessageTarget.textContent = '';
    }
  }

  profileDetailsSaveValidation(e){

    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.profileFirstNameTarget.value, this.profileFirstNameErrorMessageTarget, 'First Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.profileLastNameTarget.value, this.profileLastNameErrorMessageTarget, 'Last Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.emailTarget.value, this.emailErrorMessageTarget, 'Email is required', is_valid);
    // is_valid = this.validationIsFieldEmpty(this.profileFirstNameTarget.value, this.profileFirstNameErrorMessageTarget, 'First Name is required', is_valid);
    e.preventDefault();
    if (!is_valid) {
      return;
    }
    $.ajax({
      url: '/update_user_profile',
      method: 'POST',
      data: {
        first_name: this.profileFirstNameTarget.value,
        last_name: this.profileLastNameTarget.value,
        email: this.emailTarget.value
      },
      success: (response) => {
        if (response.response_code == 409) {
          this.emailErrorMessageTarget.textContent = response.message
          // need to print error on notification
        } else if(response.response_code == 200){
          this.initializeVariables();
          this.disableProfileEdit();
          // need to print success on notification
        }else{
          // need to print error on notification
        }
      }
    })
  }
}