import { Controller } from "stimulus";
export class ErrorController extends Controller{

  validationIsFieldEmpty(value, errorElement, message, is_valid) {
    if (value == '') {
      this.showErrorMessage(errorElement, message);
      return false;
    }
    return is_valid;
  }

  showErrorMessage(errorElement, message) {
    errorElement.textContent = message;
  }

  hideErrors(e) {
    let errorElement = this.targets.find(e.target.dataset.errorelement);
    errorElement.textContent = '';
  }

  onlyNumberOnKeypressValidation(e) {
    let errorElement = this.targets.find(e.target.dataset.errorelement);
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
      errorElement.textContent = 'Only number is accepted';
    }else{
      errorElement.textContent = '';
    }
  }
}