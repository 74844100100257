// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


//- Swiper JS
// import Swiper, { Navigation, Pagination, Autoplay, EffectCube } from 'swiper';
// window.Swiper = Swiper;
// global.Swiper = Swiper;
// Swiper.use([Navigation, Pagination, Autoplay, EffectCube]);


// import GSAP and ScrollTrigger
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  nullTargetWarn: false
});
window.gsap = gsap;
global.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
global.ScrollTrigger = ScrollTrigger;


// stimulus
import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers";
// Stimulus
const application = Application.start()
const controllers = require.context('./controllers', true, /\.js$/)
application.load(definitionsFromContext(controllers));

import 'bootstrap';
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");


// require('webpack-jquery-ui');
// require('webpack-jquery-ui/css');
import "@fortawesome/fontawesome-free/js/all";

import "../stylesheets/application";
global.toastr = require("toastr");
window.toastr = require("toastr");

require("packs/custom_constant");
require("packs/checkout");
require("packs/meals");
require("packs/order_details");
require("packs/redeem_meals");
require("packs/redeem_meals_checkout");
require("packs/tooltip_custom");
