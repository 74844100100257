import { UserController } from "./user_controller"
import { UPPERCASE_REGEX, LOWERCASE_REGEX, DIGIT_REGEX } from '../custom_constant';

export default class extends UserController {
  static targets = ['confirmPassword', 'confirmPasswordErrorMessage', 'password', 'passwordErrorMessage', 'currentPassword', 'currentPasswordErrorMessage'
  ]
  connect() {
    console.log('password connected');
  }

  updatePasswordForgotPassword(e){
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.passwordTarget.value, this.passwordErrorMessageTarget, 'New Password is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.confirmPasswordTarget.value, this.confirmPasswordErrorMessageTarget, 'You need to confirm New Password', is_valid);
    let password = this.passwordTarget.value;

    if (!(UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password) && DIGIT_REGEX.test(password) && password.length >= 8)) {
      this.passwordRegistrationValidation();
      is_valid = false;
    }

    if (this.passwordTarget.value != this.confirmPasswordTarget.value) {
      this.confirmPasswordErrorMessageTarget.textContent = 'Password and Confirm Password should match';
      is_valid = false;
    }

    if (!is_valid) {
      e.preventDefault();
      return
    }
  }

  updatePassword(e) {
    e.preventDefault();
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.currentPasswordTarget.value, this.currentPasswordErrorMessageTarget, 'Current Password is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.passwordTarget.value, this.passwordErrorMessageTarget, 'New Password is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.confirmPasswordTarget.value, this.confirmPasswordErrorMessageTarget, 'You need to confirm New Password', is_valid);
    let password = this.passwordTarget.value;

    if (!(UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password) && DIGIT_REGEX.test(password) && password.length >= 8)) {
      this.passwordRegistrationValidation();
      is_valid = false;
    }

    if (this.passwordTarget.value != this.confirmPasswordTarget.value) {
      this.confirmPasswordErrorMessageTarget.textContent = 'Password and Confirm Password should match';
      is_valid = false;
    }

    if (!is_valid) {
      return
    }

    $(e.target).html("<i class='fa fa-spinner fa-spin'></i> Saving...")
    e.target.disabled = true
    // data-disable-with = "<i class='fa fa-spinner fa-spin'></i> Saving..."
    console.log('working');

    $.ajax({
      url: '/update_current_user_password',
      method: 'POST',
      data: {
        new_password: this.passwordTarget.value,
        confirm_password: this.confirmPasswordTarget.value,
        current_user: this.currentPasswordTarget.value
      },
      success: (response) => {
        if (response.response_code == 200) {
          location.reload();
          toastr.success('Successfully updated Password')
        } else if(response.response_code == 406){
          this.currentPasswordErrorMessageTarget.textContent = 'wrong current password'
          $(e.target).html("CHANGE PASSWORD")
          e.target.disabled = false
        }else{
          toastr.error('There was an error while updating Password, try again later!!')
          $(e.target).html("CHANGE PASSWORD")
          e.target.disabled = false
        }
      }
    })
  }

  validateForgotPasswordUpdate(e){
    // console.log('clicked');
    // e.preventDefault()
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.passwordTarget.value, this.passwordErrorMessageTarget, 'New Password is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.confirmPasswordTarget.value, this.confirmPasswordErrorMessageTarget, 'You need to confirm new password', is_valid);
    let password = this.passwordTarget.value;

    if (!(UPPERCASE_REGEX.test(password) && LOWERCASE_REGEX.test(password) && DIGIT_REGEX.test(password) && password.length >= 8)) {
      this.passwordRegistrationValidation();
      is_valid = false;
    }

    if (this.passwordTarget.value != this.confirmPasswordTarget.value) {
      this.confirmPasswordErrorMessageTarget.textContent = 'Password and Confirm Password should match';
      is_valid = false;
    }

    if (!is_valid) {
      e.preventDefault();
      return
    }

  }
}