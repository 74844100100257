// import { Controller } from "stimulus";
import { ErrorController } from "./error_controller.js"
import { EMAIL_REGEX, UPPERCASE_REGEX, LOWERCASE_REGEX, DIGIT_REGEX } from '../custom_constant';

export class UserController extends ErrorController {

  static targets = ['passwordUpperCaseErrorMessage', 'passwordLowerCaseErrorMessage',
  'password', 'passwordErrorMessage', 'passwordDigitErrorMessage', 'passwordCountErrorMessage',
  'email', 'emailErrorMessage', 'otpErrorMessage', 'referralCode', 'referralCodeErrorMessage']

  is_email_valid = false;
  is_mobile_valid = false;
  is_referral_valid = false;

  mobileNumberOnKeypressValidation(e) {
    let errorElement = this.mobileNumberErrorMessageTarget;
    console.log(e.which);
    if (e.which == 13 ) {
      if (this.triggerOTPTarget) this.triggerOTPTarget.click()

      return
    }
    if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
      e.preventDefault();
      errorElement.textContent = 'Enter only digit';
    }else{
      errorElement.textContent = '';
    }
  }

  validateMobileNumberExistence(e){
    console.log('lost');
    let mobileNumber = this.mobileNumberTarget.value
    if (mobileNumber.length != 10) {
      this.mobileNumberErrorMessageTarget.textContent = 'Mobile number is not valid'
      return
    }
    $.ajax({
      url: '/validate_mobile_for_existence',
      method: 'GET',
      async: false,
      data: {
        mobile_number: this.mobileNumberTarget.value
      },
      success: response => {
        if (!response.valid) {
          this.mobileNumberErrorMessageTarget.textContent = response.message
          this.is_mobile_valid = false;
        }else{
          this.is_mobile_valid = true;
        }
      }
    })
  }

  validateReferralCodeExistence(e){
    if (this.referralCodeTarget.value == '') {
      this.is_referral_valid = true;
      return
    }
    $.ajax({
      url: '/validate_referral_for_existence',
      type: 'get',
      async: false,
      data: {
        referral: this.referralCodeTarget.value
      },
      success: response => {
        if (response.response_code == 404) {
          this.referralCodeErrorMessageTarget.textContent = 'Invalid Code'
          this.is_referral_valid = false;
        }else{
          this.referralCodeErrorMessageTarget.textContent = ''
          this.is_referral_valid = true;
        }
      }
    })
  }

  validateEmailExistence(e) {
    let email = this.emailTarget.value;
    let emailErrorMessage = this.emailErrorMessageTarget;
    console.log('logged');
    if (!EMAIL_REGEX.test(email)) {
      emailErrorMessage.textContent = 'Email is not Valid';
      return;
    } else {
      emailErrorMessage.textContent = '';
    }
    $.ajax({
      url: '/validate_email_for_existence',
      type: 'get',
      async: false,
      data: {
        email: email
      },
      success: (response) => {
        if (response.valid) {
          this.is_email_valid = true;
        } else {
          this.is_email_valid = false;
          emailErrorMessage.textContent = response.message;
        }
      }
    })
  }

  passwordRegistrationValidation(e) {
    let passwordUpperCaseErrorMessage = this.passwordUpperCaseErrorMessageTarget;
    let passwordLowerCaseErrorMessage = this.passwordLowerCaseErrorMessageTarget;
    let passwordDigitErrorMessage = this.passwordDigitErrorMessageTarget;
    let passwordCountErrorMessage = this.passwordCountErrorMessageTarget;
    let password = this.passwordTarget.value;
    let passwordErrorMessage = this.passwordErrorMessageTarget;

    passwordErrorMessage.textContent = '';
    passwordUpperCaseErrorMessage.textContent = 'Password should contain one Upper case letter';
    passwordLowerCaseErrorMessage.textContent = 'Password should contain one Lower case letter';
    passwordDigitErrorMessage.textContent = 'Password should contain one Digit';
    passwordCountErrorMessage.textContent = 'Password should contain 8 characters';

    if (UPPERCASE_REGEX.test(password)) {
      passwordUpperCaseErrorMessage.classList.remove('am-form__input-error');
      passwordUpperCaseErrorMessage.classList.add('am-form__input-valid');
    } else {
      passwordUpperCaseErrorMessage.classList.remove('am-form__input-valid');
      passwordUpperCaseErrorMessage.classList.add('am-form__input-error');
    }

    if (LOWERCASE_REGEX.test(password)) {
      passwordLowerCaseErrorMessage.classList.remove('am-form__input-error');
      passwordLowerCaseErrorMessage.classList.add('am-form__input-valid');
    } else {
      passwordLowerCaseErrorMessage.classList.remove('am-form__input-valid');
      passwordLowerCaseErrorMessage.classList.add('am-form__input-error');
    }

    if (DIGIT_REGEX.test(password)) {
      passwordDigitErrorMessage.classList.remove('am-form__input-error');
      passwordDigitErrorMessage.classList.add('am-form__input-valid');
    } else {
      passwordDigitErrorMessage.classList.remove('am-form__input-valid');
      passwordDigitErrorMessage.classList.add('am-form__input-error');
    }

    if (password.length >= 8) {
      passwordCountErrorMessage.classList.remove('am-form__input-error');
      passwordCountErrorMessage.classList.add('am-form__input-valid');
    } else {
      passwordCountErrorMessage.classList.remove('am-form__input-valid');
      passwordCountErrorMessage.classList.add('am-form__input-error');
    }
  }

  moveCursor(e){
    let ascii = e.target.value.charCodeAt(0)
    if (ascii != 8 && ascii != 0 && (ascii < 48 || ascii > 57)) {
      this.otpErrorMessageTarget.textContent = 'Enter only numbers'
      e.target.value = ''
    }else{
      if (e.target.dataset.moveCurserTo) {
        this.targets.find(e.target.dataset.moveCurserTo).focus()
        this.otpErrorMessageTarget.textContent = ''
      }
    }
  }

  displayPassword(e){
    let passwordElement = this.targets.find(e.target.dataset.showElement)
    if (e.target.classList.contains('icon-show-password')){
      passwordElement.type = "text"
      e.target.classList.remove("icon-show-password");
      e.target.classList.add("icon-hide-password");
    }else{
      passwordElement.type = "password"
      e.target.classList.add("icon-show-password");
      e.target.classList.remove("icon-hide-password");
    }
  }
}