// import { Controller } from "stimulus";
import { ErrorController } from "./error_controller.js"
import { EMAIL_REGEX } from '../custom_constant';

export default class extends ErrorController {

  static targets = [
    'firstName', 'lastName', 'email', 'description', 'firstNameErrorMessage',
    'lastNameErrorMessage', 'emailErrorMessage', 'descriptionErrorMessage'
  ]

  connect() {
    console.log('connected contact us');
  }

  save(e) {
    let is_valid = true;
    is_valid = this.validationIsFieldEmpty(this.firstNameTarget.value, this.firstNameErrorMessageTarget, 'First Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.lastNameTarget.value, this.lastNameErrorMessageTarget, 'Last Name is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.emailTarget.value, this.emailErrorMessageTarget, 'Email is required', is_valid);
    is_valid = this.validationIsFieldEmpty(this.descriptionTarget.value, this.descriptionErrorMessageTarget, 'Enter brief description for better service ', is_valid);

    if (!EMAIL_REGEX.test(this.emailTarget.value)) {
      this.showErrorMessage(this.emailErrorMessageTarget, 'Invalid email');
      is_valid = false;
    }

    if (!is_valid) {
      e.preventDefault();
    }
  }

}