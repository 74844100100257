window.bootstrap = require("bootstrap");
document.addEventListener('turbolinks:load', ()=> {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });

  $('#location_fresno_west').tooltip({
    title: '<div class="leading-tight text-start"><div class="text-uppercase text-xs"><strong>Grab n Go Only</strong></div> <div class="text-lowercase text-xxs">(do not need any membership to purchase)</div> <div class="text-xs pt-2">6783 N Milburn Ave, Fresno, CA 93722 (Inside GB3)</div></div>',
    html: true,
    placement: 'bottom',
    container: 'body'
  });
  $('#location_fresno').tooltip({
    title: '<div class="leading-tight text-start"><div class="text-uppercase text-xs"><strong>Pickup and Grab n Go Available</strong></div> <div class="text-xs pt-2">7757 N First St Fresno, CA 93720</div></div>',
    html: true,
    placement: 'bottom',
    container: 'body'
  });
  $('#location_clovis').tooltip({
    title: '<div class="leading-tight text-start"><div class="text-uppercase text-xs"><strong>Pickup and Grab n Go Available</strong></div> <div class="text-xs pt-2">1885 Herndon Ave, Clovis, CA 93611 (Inside GB3)</div></div>',
    html: true,
    placement: 'bottom',
    container: 'body'
  });
  $('#location_fresno_south').tooltip({
    title: '<div class="leading-tight text-start"><div class="text-uppercase text-xs"><strong>Grab n Go Only</strong></div> <div class="text-xs pt-2">6149 E Kings Canyon Rd, Fresno, CA 93727 (Inside GB3)</div></div>',
    html: true,
    placement: 'bottom',
    container: 'body'
  });
});