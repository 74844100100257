// window.reorder = sub_order_id => {
// 	$.ajax({
//     url: '/create_reorder',
//     method: 'POST',
//     data: {
//       sub_order_id: sub_order_id
//     },
//     success: (response) => {
//       if (response.response_code == 200) {
//       	alert("Reorders Successfully!")
//       }
//     }
// 	});
// }

window.add_to_cart_reorder = sub_order_id => {
  console.log("sub_order_id::", sub_order_id)
  $.ajax({
    url: '/reorder_add_cart',
    method: 'POST',
    data: {
      sub_order_id: sub_order_id
      //quantity: parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.type}${e.target.dataset.id}`).textContent)

    },
    success: (response) => {
      if (response.response_code == 200) {
        location.reload();
      } else if (response.response_code == 406) {
        toastr.warning(response.message)
      } else {
        toastr.error('Something went wrong, please try again later!!')
      }
    }
  })
}

window.add_to_cart_reorder_all = sub_order_ids => {
  console.log("sub_order_ids::", sub_order_ids)
  $.ajax({
    url: '/reorder_add_cart_all',
    method: 'POST',
    data: {
      sub_order_ids: sub_order_ids
      //quantity: parseInt(this.targets.find(`quantityMealMenu${e.target.dataset.type}${e.target.dataset.id}`).textContent)

    },
    success: (response) => {
      if (response.response_code == 200) {
        location.reload();
      } else if (response.response_code == 406) {
        toastr.warning(response.message)
      } else if(response.response_code == 422) {
        toastr.warning(response.message)
      } else {
        toastr.error('Something went wrong, please try again later!!')
      }
    }
  })
}

window.order_detail_address_update = (order_id) => {
  let customer_pickup_id = '';
  let delivery_address_id = '';
  let delivery_type = '';

  let selected_delivery_type = $('input[name=delivery_type]:checked').val()

  if (selected_delivery_type == "pickup") {
    customer_pickup_id = $('input[name=customer_pickup]:checked').val()
    delivery_type = 'Pickup'
  } else {
    delivery_address_id = $('input[name=customer_delivery]:checked').val()
    delivery_type = 'Delivery'
  }
  $('#udateDeliveryOrderSummary').prop('disabled', true)
  $('#udateDeliveryOrderSummary').html("<i class='fa fa-spinner fa-spin'></i> Placing Order...")

  $.ajax({
    url: '/modify_address',
    method: 'POST',
    data: {
      order_id: order_id,
      pickup_address_id: customer_pickup_id,
      delivery_address_id: delivery_address_id,
      delivery_type: delivery_type
    },
    success: (response) => {
      if (response.response_code == 200) {
        toastr.success('Address Modified Successfully!')
        $('#choosen_address').html(response.address)
        $('#change_preferences').modal('hide')
        location.reload()
      } else if (response.response_code == 400) {
        toastr.error('Select any address')
        $('#udateDeliveryOrderSummary').prop('disabled', false)
        $('#udateDeliveryOrderSummary').html("UPDATE")
      }
    }
  });
}

window.read_notification = (user_id) => {
  console.log('read_notification====>', user_id)
  $.ajax({
    url: '/clear_red_notifications',
    method: 'GET',
    data: {
      user_id: user_id
    },
    success: (response) => {
      if (response.response_code == 200) {
        toastr.success('Notifications read Successfully!');

      }
    }
  });
}

window.select_card = (order_id) => {
  console.log('select_card', order_id)
  let selected_card = $('input[name=saved_cards]:checked').val()
  console.log("selected_card====>", selected_card);
  $.ajax({
    url: '/select_card',
    method: 'GET',
    data: {
      order_id: order_id,
      selected_user_card: selected_card
    },
    success: (response) => {
      if (response.response_code == 200) {
        $('#select_saved_card').modal('hide')
        location.reload();
        toastr.success('Successfully change the card')
      }
    }
  })
}